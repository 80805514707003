import { DetailConfig, EditorComponent, FormBehavior, newConfig, Nx3Api, TaskHeaderComponent } from '@nx3/nx3-core-ui';
import { MinwageUserTaskDescriptionComponent } from '../minwage-usertask-description/minwage-usertask-description.component';
import { MultiDocumentUploadUserTaskExtension } from './multi-document-upload-usertask-extension';


export class MinwageUploadUserTaskExtension extends MultiDocumentUploadUserTaskExtension {

  constructor(public nx3: Nx3Api) {
    super(nx3, 'minwagedocumentuploadusertask', 'minWageFirstDocument', ['minWageSecDocument', 'minWageThirdDocument']);
  }

  configureDetail(config: DetailConfig): void {
    config.enablePlaces = false;
    config.windowTitle = false;
    config.header.visible = false;

    const editorConfig = {
      form: {
        enableSectionHeaders: true,
        formBehavior: (behavior: FormBehavior) => {
          this.documentUploadService.multiDocumentUploadFormBehavior(this.editorInstance, behavior, 'minWageFirstDocument', ['minWageSecDocument', 'minWageThirdDocument']);
        }
      }
    };

    config.layout
      .rootLayout()
      .withUseContainer(false)
      .withPadding(2, 0)
      .row()
      .component(TaskHeaderComponent, newConfig(12))
      .component(MinwageUserTaskDescriptionComponent, newConfig(12))
      .component(EditorComponent, newConfig(12).mapModel('entity', 'object'), {configOverride: editorConfig})
      .up();
  }

}
