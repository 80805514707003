import { Component, OnInit } from '@angular/core';
import { BooleanFieldWidgetComponent, Nx3Api } from '@nx3/nx3-core-ui';

@Component({
  selector: 'app-disabled-field',
  templateUrl: './disabled-field.component.html',
  styleUrls: ['./disabled-field.component.scss'],
})
export class DisabledFieldComponent extends BooleanFieldWidgetComponent implements OnInit {

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  ngOnInit(): void {
    this.readOnlyValue = this.object.virtual.userDetails?.disabled;
  }
}
