import { Component, Input, OnInit } from '@angular/core';
import { ResourceConfig } from '@nx3/nx3-client';
import { Nx3Api } from '@nx3/nx3-core-ui';

@Component({
  selector: 'lib-qualifications-current-org',
  templateUrl: './qualifications-current-org.html',
  styleUrls: ['./qualifications-current-org.scss'],
})
export class QualificationsCurrentOrgComponent implements OnInit {
  @Input() resourceConfig: ResourceConfig;

  partner: any;

  constructor(public nx3: Nx3Api) {}

  ngOnInit(): void {
    this.nx3.client
      .getPersonAndOrganization(this.resourceConfig.server)
      .subscribe((partner) => {
        this.partner = partner.organization;
      });
  }
}
