import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Nx3Api, PageFooterComponent } from '@nx3/nx3-core-ui';


@Component({
  selector: 'app-portal-footer',
  templateUrl: './portal-footer.component.html',
  styleUrls: ['./portal-footer.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('500ms ease-in', style({ opacity: '1' })),
      ]),
      transition(':leave', [animate('250ms ease-in', style({ opacity: '0' }))]),
    ]),
  ],
})
export class PortalFooterComponent extends PageFooterComponent {
  constructor(public nx3: Nx3Api) {
    super(nx3);
  }
}
