import { Injectable } from '@angular/core';
import { EditorComponent, FormBehavior, Nx3Api } from '@nx3/nx3-core-ui';
import { ValidatorFn, AbstractControl, ValidationErrors, Validators } from '@angular/forms';


/**
 * Date and datetime utils used by components
 *
 * @author martin.bueltel(at)serie-a.de
 */
@Injectable({
  providedIn: 'root',
})
export class FleetService {
  semiTrailerRefrigeratedMonoTemperatureCount;
  semiTrailerRefrigeratedBiTemperatureCount;
  semiTrailerRefrigeratedDoubleDeckerCount;
  semiTrailerRefrigeratedNoDoubleStockCount;

  constructor(public nx3: Nx3Api) { }

  smallFleetFormBehavior(editorComponent: EditorComponent, behavior: FormBehavior, subField?: string) {
    const subFieldPath = subField ? subField + '.' : '';
    const fleetNumbers = [
      ['count3TonDry', 'count3TonCooling'],
      ['count7TonDry', 'count7TonCooling'],
      ['count12TonDry', 'count12TonCooling'],
      ['count16TonDry', 'count16TonCooling'],
      ['count18TonDry', 'count18TonCooling']
    ];

    for(const fleet of fleetNumbers) {
      behavior
        .global(() => true)
        .setRequired(subFieldPath+fleet[0], true)
        // .setValidators('smallFleetTemp.'+fleet[1], [Validators.max(editorComponent.entity.smallFleetTemp[fleet[0]])])
        .setValidators(subFieldPath+fleet[1], [Validators.max(subField ? editorComponent.entity[subField][fleet[0]] : editorComponent.entity[fleet[0]])])
      ;

      behavior
        .when(subFieldPath+fleet[0], (object: any) => {
          if(subField) {
            object = object[subField];
          }
          return object[fleet[0]];
        })
        .run((object: any) => {
          if(editorComponent.form) {
            if(subField) {
              object = object[subField];
            }
            const field = editorComponent.form.get(subFieldPath+fleet[1]);
            field.setValidators([Validators.max(object[fleet[0]])]);
            field.updateValueAndValidity();
          }
        })
      ;
    }
  }

  largeFleetFormBehavior(behavior: FormBehavior, subField?: string) {
    const subFieldPath = subField ? subField + '.' : '';
    behavior
      .when(subFieldPath+'semiTrailerTarpaulinCount', (object: any) => {
        if(subField) {
          object = object[subField];
        }
        return object.semiTrailerTarpaulinCount;
      })
      .setValue(subFieldPath+'semiTrailerTotalCount', (object: any) => {
        if(subField) {
          object = object[subField];
        }
        return +object.semiTrailerTarpaulinCount + +object.semiTrailerRefrigeratedCount;
      })
    ;

    behavior
      .when(subFieldPath+'semiTrailerRefrigeratedCount', (object: any) => {
        if(subField) {
          object = object[subField];
        }
        return object.semiTrailerRefrigeratedCount;
      })
      .setValue(subFieldPath+'semiTrailerTotalCount', (object: any) => {
        if(subField) {
          object = object[subField];
        }
        return +object.semiTrailerTarpaulinCount + +object.semiTrailerRefrigeratedCount;
      })
      .setValidators(subFieldPath+'semiTrailerRefrigeratedMonoTemperatureCount', this.validateRefrigeratedCount())
      .setValidators(subFieldPath+'semiTrailerRefrigeratedBiTemperatureCount', this.validateRefrigeratedCount())
      .setValidators(subFieldPath+'semiTrailerRefrigeratedDoubleDeckerCount', this.validateRefrigeratedCount())
      .setValidators(subFieldPath+'semiTrailerRefrigeratedNoDoubleStockCount', this.validateRefrigeratedCount())
    ;

    behavior
      .when(subFieldPath+'semiTrailerRefrigeratedMonoTemperatureCount', (object: any) => {
        if(subField) {
          object = object[subField];
        }
        return object.semiTrailerRefrigeratedMonoTemperatureCount != this.semiTrailerRefrigeratedMonoTemperatureCount;
      })
      .run((object: any) => {
        if(subField) {
          object = object[subField];
        }
        this.semiTrailerRefrigeratedMonoTemperatureCount = +object.semiTrailerRefrigeratedMonoTemperatureCount;
      })
      .setValidators(subFieldPath+'semiTrailerRefrigeratedBiTemperatureCount', this.validateRefrigeratedCount())
      .setValidators(subFieldPath+'semiTrailerRefrigeratedDoubleDeckerCount', this.validateRefrigeratedCount())
      .setValidators(subFieldPath+'semiTrailerRefrigeratedNoDoubleStockCount', this.validateRefrigeratedCount())
      .markAsTouched(subFieldPath+'semiTrailerRefrigeratedBiTemperatureCount')
      .markAsTouched(subFieldPath+'semiTrailerRefrigeratedDoubleDeckerCount')
      .markAsTouched(subFieldPath+'semiTrailerRefrigeratedNoDoubleStockCount')
    ;

    behavior
      .when(subFieldPath+'semiTrailerRefrigeratedBiTemperatureCount', (object: any) => {
        if(subField) {
          object = object[subField];
        }
        return object.semiTrailerRefrigeratedBiTemperatureCount != this.semiTrailerRefrigeratedBiTemperatureCount;
      })
      .run((object: any) => {
        if(subField) {
          object = object[subField];
        }
        this.semiTrailerRefrigeratedBiTemperatureCount = +object.semiTrailerRefrigeratedBiTemperatureCount;
      })
      .setValidators(subFieldPath+'semiTrailerRefrigeratedMonoTemperatureCount', this.validateRefrigeratedCount())
      .setValidators(subFieldPath+'semiTrailerRefrigeratedDoubleDeckerCount', this.validateRefrigeratedCount())
      .setValidators(subFieldPath+'semiTrailerRefrigeratedNoDoubleStockCount', this.validateRefrigeratedCount())
      .markAsTouched(subFieldPath+'semiTrailerRefrigeratedMonoTemperatureCount')
      .markAsTouched(subFieldPath+'semiTrailerRefrigeratedDoubleDeckerCount')
      .markAsTouched(subFieldPath+'semiTrailerRefrigeratedNoDoubleStockCount')
    ;

    behavior
      .when(subFieldPath+'semiTrailerRefrigeratedDoubleDeckerCount', (object: any) => {
        if(subField) {
          object = object[subField];
        }
        return object.semiTrailerRefrigeratedDoubleDeckerCount != this.semiTrailerRefrigeratedDoubleDeckerCount;
      })
      .run((object: any) => {
        if(subField) {
          object = object[subField];
        }
        this.semiTrailerRefrigeratedDoubleDeckerCount = +object.semiTrailerRefrigeratedDoubleDeckerCount;
      })
      .setValidators(subFieldPath+'semiTrailerRefrigeratedMonoTemperatureCount', this.validateRefrigeratedCount())
      .setValidators(subFieldPath+'semiTrailerRefrigeratedBiTemperatureCount', this.validateRefrigeratedCount())
      .setValidators(subFieldPath+'semiTrailerRefrigeratedNoDoubleStockCount', this.validateRefrigeratedCount())
      .markAsTouched(subFieldPath+'semiTrailerRefrigeratedMonoTemperatureCount')
      .markAsTouched(subFieldPath+'semiTrailerRefrigeratedBiTemperatureCount')
      .markAsTouched(subFieldPath+'semiTrailerRefrigeratedNoDoubleStockCount')
    ;

    behavior
      .when(subFieldPath+'semiTrailerRefrigeratedNoDoubleStockCount', (object: any) => {
        if(subField) {
          object = object[subField];
        }
        return object.semiTrailerRefrigeratedNoDoubleStockCount != this.semiTrailerRefrigeratedNoDoubleStockCount;
      })
      .run((object: any) => {
        if(subField) {
          object = object[subField];
        }
        this.semiTrailerRefrigeratedNoDoubleStockCount = +object.semiTrailerRefrigeratedNoDoubleStockCount;
      })
      .setValidators(subFieldPath+'semiTrailerRefrigeratedMonoTemperatureCount', this.validateRefrigeratedCount())
      .setValidators(subFieldPath+'semiTrailerRefrigeratedBiTemperatureCount', this.validateRefrigeratedCount())
      .setValidators(subFieldPath+'semiTrailerRefrigeratedDoubleDeckerCount', this.validateRefrigeratedCount())
      .markAsTouched(subFieldPath+'semiTrailerRefrigeratedMonoTemperatureCount')
      .markAsTouched(subFieldPath+'semiTrailerRefrigeratedBiTemperatureCount')
      .markAsTouched(subFieldPath+'semiTrailerRefrigeratedDoubleDeckerCount')
    ;
  }

  validateRefrigeratedCount(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const expected = control.parent.get('semiTrailerRefrigeratedCount').value;
      const a = +control.parent.get('semiTrailerRefrigeratedMonoTemperatureCount').value;
      const b = +control.parent.get('semiTrailerRefrigeratedBiTemperatureCount').value;
      const c = +control.parent.get('semiTrailerRefrigeratedDoubleDeckerCount').value;
      const d = +control.parent.get('semiTrailerRefrigeratedNoDoubleStockCount').value;
      const sum = a + b + c + d;
      const message = this.nx3.i18n.translate.instant('error_calculation_less_than', {value: expected});
      if(sum !== +expected) {
        return {refrigeratedCount: {message: message}};
      }
      return null;
    }
  }

}
