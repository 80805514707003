<div class="card">
  <div class="card-body p-2 pb-0">
    <div *ngIf="documentTemplateDownload" class="col-4 pb-3">
      <nx3-form-field
        [object]="entity"
        [metadata]="metadata"
        [field]="documentTemplateDownload"
        [resourceConfig]="resourceConfig"
      ></nx3-form-field>
    </div>
    <p *ngIf="textBefore" [innerHtml]="textBefore | safe: 'html'"></p>
    <table *ngIf="liabilityamounts?.length > 0" class="table table-bordered">
      <thead class="table-header">
        <tr>
          <th><span [innerHtml]="'property_area_label' | translate | safe: 'html'"></span></th>
          <th><span class="d-flex" [innerHtml]="'property_nationalEuExclEast_label' | translate | safe: 'html'"></span></th>
          <th><span class="d-flex" [innerHtml]="'property_nationalInEastEu_label' | translate | safe: 'html'"></span></th>
          <th><span class="d-flex" [innerHtml]="'property_crossBorder_label' | translate | safe: 'html'"></span></th>
          <th><span class="d-flex" [innerHtml]="'property_cabotageDe_label' | translate | safe: 'html'"></span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let liability of liabilityamounts">
          <th class="table-header"><span>{{liability.area.text}}</span></th>
          <td><span>{{liability.nationalEuExclEast | currency: 'EUR'}}</span></td>
          <td><span>{{liability.nationalInEastEu | currency: 'EUR'}}</span></td>
          <td><span>{{liability.crossBorder | currency: 'EUR'}}</span></td>
          <td><span>{{liability.cabotageDe | currency: 'EUR'}}</span></td>
        </tr>
      </tbody>
    </table>
    <p *ngIf="textAfter" [innerHtml]="textAfter | safe: 'html'"></p>
  </div>
</div>
