import { DetailConfig, ListConfig, Nx3Api, Nx3RegionExtension } from '@nx3/nx3-core-ui';

export class CustomRegionExtension extends Nx3RegionExtension {

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  configureList(config: ListConfig): void {
    config.setFixedView(false);
    config.table.toolDetail = false;
    config.enableCount = false;
    config.enableDuplicate = false;
    config.enableListSettings = false;
    config.enableGuidedTour = false;
    config.enableNotifications = false;
    config.pagination.pageSizes = [10, 25, 50, 75, 100];
  }

  configureDetail(config: DetailConfig): void {
    super.configureDetail(config);
    config.enableNotifications = false;
  }

}
