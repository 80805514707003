<footer
  *ngIf="visible"
  [@slideInOut]
  class="footer nx3-unselectable"
>
  <div class="container-fluid p-3">
    <div class="d-flex justify-content-center">
        <span>
          Nagel-Group Logistics SE - All rights reserved
        </span>
        <a class="text-light ms-5" href="https://www.nagel-group.com/impressum/" target="_blank"
          translate>nx3_label_impressum</a>
        <nx3-platform-agreement-links
          modelType="de.seriea.nx3.nagel.carrier.carrier.Carrier"
          [server]="nx3.config.registrationServer"
        ></nx3-platform-agreement-links>
    </div>
  </div>
</footer>
