import { Nx3ColumnModel, Nx3DataType, Nx3Entity } from '@nx3/nx3-client';
import {
  DetailConfig,
  DetailEvent,
  EmployeeExtension,
  ListConfig,
  ListEvent,
  Nx3Api
} from '@nx3/nx3-core-ui';
import { DisabledFieldComponent } from 'projects/shared/src/lib/disabled-field/disabled-field.component';
import { BehaviorSubject } from 'rxjs';
import { ContactPageComponent } from '../pages/contact-page/contact-page.component';

export class CustomEmployeeExtension extends EmployeeExtension {

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  postConstruct(): void {
    super.postConstruct();
    this.config.organizationInEditor = false;
    this.config.simpleDetails = true;
    this.config.inviteUser = false;
    this.config.createUser = false;
  }

  onListInit(event: ListEvent) {
    const disabledColumn = event.component.metadata.getColumnByName('userDisabled');
    if (!disabledColumn) {
      const disabledField = new Nx3ColumnModel();
      disabledField.labelKey = 'property_disabled_label';
      disabledField.name = 'userDisabled';
      disabledField.visible = true;
      disabledField.type = Nx3DataType.BOOLEAN;
      disabledField.componentType = DisabledFieldComponent;
      event.component.metadata.list.columns.push(disabledField);
    }
  }

  onBeforeSave(entity: Nx3Entity): void {
    entity.requestedUserName = entity.actor.communication.emailWork;
  }

  onDetailInit(event: DetailEvent): void {
    if(this.nx3.getCurrentRouteComponent() instanceof ContactPageComponent) {
      event.component.config.header.visible = false;
      event.component.metadata.getFormSectionByLabel('section_main').hiddenDetail = true;
      event.component.metadata.getFormSectionByLabel('section_communication').hiddenDetail = true;
      event.component.metadata.getFormSectionByLabel('section_contact').hiddenDetail = false;
    } else {
      event.component.metadata.getFormSectionByLabel('section_contact').hiddenDetail = true;
    }
  }

  configureDetail(config: DetailConfig): void {
    super.configureDetail(config);
    config.enableNotifications = false;
  }

  configureList(config: ListConfig) {
    this.activateExists = new BehaviorSubject(false);
    this.deactivateExists = new BehaviorSubject(false);
    this.activateDisabled = new BehaviorSubject(true);
    this.deactivateDisabled = new BehaviorSubject(true);
    this.passwordResetExists = new BehaviorSubject(false);
    this.passwordResetDisabled = new BehaviorSubject(true);
    this.changeOrgExists = new BehaviorSubject(false);

    config.setFixedView(false);
    config.enableCount = false;
    config.enableDuplicate = false;
    config.enableListSettings = false;
    config.enableGuidedTour = false;
    config.enableNotifications = false;
    config.pagination.pageSizes = [10, 25, 50, 75, 100];
    config.filter.hiddenFilterFields.push('ownEmployees', 'specification.userDetails', 'specification.invitation');
  }

}
