import { Nx3Entity } from '@nx3/nx3-client';
import { DetailComponent, DetailConfig, EditorComponent, EditorEvent, FormBehavior, ListComponent, ModalComponent, newConfig, Nx3Api, Nx3UserTaskExtension, TaskHeaderComponent } from '@nx3/nx3-core-ui';
import { DocumentUploadService } from '../service/document-upload.service';


export abstract class MultiDocumentUploadUserTaskExtension extends Nx3UserTaskExtension {
  documentUploadService: DocumentUploadService;

  constructor(public nx3: Nx3Api, public resourceName: string, public mainDocument: string, public optionalDocuments: string[]) {
    super(nx3, resourceName);
    this.documentUploadService = this.nx3.injector.get(DocumentUploadService);
  }

  onEditorInit(event: EditorEvent): void {
    if(event.component.entity.hideValidity) {
      event.component.metadata.getFieldByName(this.mainDocument + '.validFrom').hidden = true;
      event.component.metadata.getFieldByName(this.mainDocument + '.validTo').hidden = true;
    } else {
      event.component.metadata.getFieldByName(this.mainDocument + '.validFrom').hidden = false;
      event.component.metadata.getFieldByName(this.mainDocument + '.validTo').hidden = false;
    }
  }

  configureDetail(config: DetailConfig): void {
    config.enablePlaces = false;
    config.windowTitle = false;
    config.header.visible = false;

    const editorConfig = {
      form: {
        enableSectionHeaders: true,
        formBehavior: (behavior: FormBehavior) => {
          this.documentUploadService.multiDocumentUploadFormBehavior(this.editorInstance, behavior, this.mainDocument, this.optionalDocuments);
        }
      }
    };

    config.layout
      .rootLayout()
      .withUseContainer(false)
      .withPadding(2, 0)
      .row()
      .component(TaskHeaderComponent, newConfig(12))
      .component(EditorComponent, newConfig(12).mapModel('entity', 'object'), {configOverride: editorConfig})
      .up();
  }

  onBeforeSave(entity: Nx3Entity) {
    this.documentUploadService.multiDocumentUploadOnBeforeSave(entity, this.mainDocument, this.optionalDocuments);
  }

  onEditorDestroy(event: EditorEvent): void {
    this.documentUploadService.unregisterDropzoneEvents(event.component);
  }

  configureModal(list: ListComponent, detail: DetailComponent, modalInstance: ModalComponent): void {
    super.configureModal(list, detail, modalInstance);
    this.documentUploadService.disableCompleteOnInvalidForm(detail, modalInstance);
  }

}
