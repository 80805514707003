import { DetailConfig, EditorComponent, FormBehavior, newConfig, Nx3Api, TaskHeaderComponent } from '@nx3/nx3-core-ui';
import { LiabilityamountUserTaskDescriptionComponent } from '../ins-sub-contr-transp-usertask-description/liabilityamount-usertask-description.component';
import { MultiDocumentUploadUserTaskExtension } from './multi-document-upload-usertask-extension';


export class InsSubContrTranspUploadUserTaskExtension extends MultiDocumentUploadUserTaskExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3, 'inssubcontrtranspuploadusertask', 'insurance', ['supplementaryDocument']);
  }

  configureDetail(config: DetailConfig): void {
    config.enablePlaces = false;
    config.windowTitle = false;
    config.header.visible = false;

    const editorConfig = {
      form: {
        enableSectionHeaders: true,
        formBehavior: (behavior: FormBehavior) => {
          this.documentUploadService.multiDocumentUploadFormBehavior(this.editorInstance, behavior, 'insurance', ['supplementaryDocument']);
        }
      }
    };

    config.layout
      .rootLayout()
      .withUseContainer(false)
      .withPadding(2, 0)
      .row()
      .component(TaskHeaderComponent, newConfig(12))
      .component(LiabilityamountUserTaskDescriptionComponent, newConfig(12), {documentType: 'insuranceSubcontractorsTransport'})
      .component(EditorComponent, newConfig(12).mapModel('entity', 'object'), {configOverride: editorConfig})
      .up();
  }

}
