import { ContentPanelConfig, DetailConfig, DynamicComponentConfig, EditorConfig, FormlayoutComponent, ListConfig, Nx3Api, nestedConfig } from '@nx3/nx3-core-ui';
import { AbstractPortalExtension } from './abstract-portal-extension';
import { CarrierRelationTableMapComponent } from 'projects/shared/src/lib/carrier-relation-table-map/carrier-relation-table-map.component';

export class CarrierRelationExtension extends AbstractPortalExtension {

  constructor(public nx3: Nx3Api) {
    super('carrierrelation', nx3);
  }

  configureEditor(config: EditorConfig): void {
    config.componentExtensions.addSouthComponent(new DynamicComponentConfig(CarrierRelationTableMapComponent));
  }

  configureDetail(config: DetailConfig): void {
       config.layout
      .rootLayout()
      .row()
      .componentPresets(this.detailLayout().topPresets)
      .up()
      .row()
      .nestedColumn(12)
      .component(
        FormlayoutComponent,
        nestedConfig(false)
          .mapModel('entity', 'object')
      )
      .component(
        CarrierRelationTableMapComponent,
        nestedConfig(false)
      )
      .exitNesting()
      .up()
      .row()
      .componentPresets(this.detailLayout().bottomPresets)
      .up();
  }

  configureList(config: ListConfig): void {
    super.configureList(config);
    config.filter.hiddenFilterFields.push('ownCarrierInvertedDefault', 'ownCarrier');
  }

}
