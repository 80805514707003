import { Nx3Entity } from '@nx3/nx3-client';
import { Nx3Api, ResourceExtension } from '@nx3/nx3-core-ui';

export class TargetExtension extends ResourceExtension {

  constructor(public nx3: Nx3Api) {
    super('target', nx3);
  }

  onAfterCreate(entity: Nx3Entity) {
    entity.language = this.nx3.client.i18n.getBowserLanguage();
  }

  onBeforeSave(entity: Nx3Entity): void {
    this.nx3.router.routerState.root.queryParams.subscribe((params) => {
      if(params.legacyApplicationProcess) {
        entity.contextStore = {
          registrationSource: "LEGACY_APPLICATION_PROCESS"
        };
      } else if(params.invitationId) {
        entity.contextStore = {
          carrierInvitationId: params.invitationId,
          registrationSource: "CAL_SUITE_INVITATION"
        };
      }
    })
  }
}
