import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractEntityComponent, ExtensionService, Nx3Api } from '@nx3/nx3-core-ui';
import { ApplicationEvent } from '@nx3/nx3-client';

@Component({
  selector: 'app-validity-warning',
  templateUrl: './validity-warning.component.html',
  styleUrls: ['./validity-warning.component.scss'],
})
export class ValidityWarningComponent extends AbstractEntityComponent implements OnInit, OnDestroy {
  calculatedValidFromExists = true;
  calculatedValidToExists = true;

  constructor(public nx3: Nx3Api, protected extensions: ExtensionService) {
    super(nx3, extensions);
  }

  ngOnDestroy(): void {
    this.nx3.client.events.unregister('customVerificationEntityChanged', this);
  }

  ngOnInit(): void {
    this.nx3.client.events.on(
      'customVerificationEntityChanged',
      (event: ApplicationEvent) => {
        this.entity = event.data;
        this.calculatedValidFromExists = this.entity.modelExtension?.calculatedValidFrom || this.entity.calculatedValidFrom;
        this.calculatedValidToExists = this.entity.modelExtension?.calculatedValidTo || this.entity.calculatedValidTo;
      },
      this
    );

    this.calculatedValidFromExists = this.entity.modelExtension?.calculatedValidFrom || this.entity.calculatedValidFrom;
    this.calculatedValidToExists = this.entity.modelExtension?.calculatedValidTo || this.entity.calculatedValidTo;
  }
}
