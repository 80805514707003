import { EditorConfig, EditorEvent, FormBehavior, Nx3Api } from '@nx3/nx3-core-ui';
import { FleetService } from 'projects/shared/src/lib/service/fleet.service';
import { AbstractPortalExtension } from './abstract-portal-extension';
import { Nx3Entity } from '@nx3/nx3-client';

export class SmallfleetExtension extends AbstractPortalExtension {
  fleetService: FleetService;

  constructor(public nx3: Nx3Api) {
    super('smallfleet', nx3);
    this.enableDetail = false;
    this.fleetService = this.nx3.injector.get(FleetService);
  }

  configureEditor(config: EditorConfig): void {
    super.configureEditor(config);
    config.enableRoutingAfterSave = false;
    config.enableCancel = false;
    config.updateMessageKey = 'nx3_message_save_successful';
    config.saveMessageAsToast = true;
  }

  onAfterSave(entity: Nx3Entity): void {
    this.nx3.navigation.reload();
  }

  onEditorInit(event: EditorEvent): void {
    event.component.config.form.formBehavior = (behavior: FormBehavior) => {
      this.fleetService.smallFleetFormBehavior(event.component, behavior);
    }
  }

}
