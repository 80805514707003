import { Component, OnInit } from '@angular/core';
import { Nx3Entity, ResourceConfig, ResourceModel } from '@nx3/nx3-client';
import { Nx3Api } from '@nx3/nx3-core-ui';
import { take } from 'rxjs';

@Component({
  selector: 'app-doc-share-category-filter',
  templateUrl: './doc-share-category-filter.component.html',
  styleUrls: ['./doc-share-category-filter.component.scss'],
})
export class DocShareCategoryFilterComponent implements OnInit {

  active = 'ALL';
  categories: Nx3Entity[] = [];

  constructor(public nx3: Nx3Api) { }

  ngOnInit(): void {
    this.nx3.client.resource
    .resourceModelByName(this.nx3.config.registrationServer, 'documentssharecategory')
    .pipe(take(1))
    .subscribe((resourceModel: ResourceModel) => {
      const resourceConfig = ResourceConfig.forResourceModel(
        this.nx3.config.registrationServer,
        resourceModel
      );
      this.nx3.client.entity.list(resourceConfig).pipe(take(1)).subscribe((categories) => {
        this.categories = categories;
      });
    });
  }

  navChange() {
    if(this.active === 'ALL') {
      this.nx3.client.events.fireEvent('DOC_SHARE_FILTER', {category: null});
    } else {
      this.nx3.client.events.fireEvent('DOC_SHARE_FILTER', {category: this.active});
    }
  }
}
