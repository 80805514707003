import { Nx3Entity } from '@nx3/nx3-client';
import { DetailConfig, DetailEvent, EditorEvent, FormBehavior, FormlayoutComponent, Nx3Api, ReferenceListConfig, ReferenceListEvent, nestedConfig } from '@nx3/nx3-core-ui';
import { TransportEquipmentService } from 'projects/shared/src/lib/service/transport-equipment.service';
import { AbstractPortalExtension } from './abstract-portal-extension';

export class TransportEquipmentExtension extends AbstractPortalExtension {
  teService: TransportEquipmentService;
  server: string;

  constructor(public nx3: Nx3Api, public resourceName = 'transportequipment') {
    super(resourceName, nx3);
    this.teService = this.nx3.injector.get(TransportEquipmentService);
    this.server = this.nx3.config.registrationServer;
  }

  configureReferenceLists(referenceListConfig: Map<string, ReferenceListConfig>): void {
    const config = new ReferenceListConfig();
    config.enableSelection = false;
    config.enableCreate = false;
    config.table.toolsVisible = false;
    referenceListConfig.set('nx3document', config);
    referenceListConfig.set('carriercontract', config);
  }

  configureDetail(config: DetailConfig): void {
    config.enableNotifications = false;

    config.layout
      .rootLayout()
      .row()
      .component(
        FormlayoutComponent,
        nestedConfig(false).mapModel('entity', 'object')
      )
      .up()
      .row()
      .componentPresets(this.detailLayout().bottomPresets)
      .up();
  }

  onAfterCreate(entity: Nx3Entity): void {
    if(entity.primaryDepartment) {
      entity.secondaryDepartments.push(entity.primaryDepartment);
    }
  }

  onEditorInit(event: EditorEvent): void {
    const temperatureZoneTypes = event.component.metadata.getFieldByName('temperatureZoneTypes');
    if(temperatureZoneTypes) {
      temperatureZoneTypes.options = temperatureZoneTypes.options.filter((type) => type.key !== 'NONE');
    }

    const payloadLiftgateInKG = event.component.metadata.getFieldByName('payloadLiftgateInKG')
    if(payloadLiftgateInKG) {
      payloadLiftgateInKG.hidden = false;
    }    event.component.config.form.formBehavior = (behavior: FormBehavior) => {
      this.teService.transportEquipmentFormBehavior(event.component, behavior);
    };

    this.teService.changesOnRelevantFields(event.component);
  }

  onReferenceListInit(event: ReferenceListEvent): void {
    super.onReferenceListInit(event);
    event.component.config.filter.hiddenFilterFields.push('showCarrierGroupTransportEquipmentDocuments');
    event.component.config.filter.hiddenFilterFields.push('showTransportEquipmentDocuments');
    event.component.config.filter.hiddenFilterFields.push('ownDocuments');
  }

  onEditorDestroy(event: EditorEvent): void {
    this.teService.onEditorDestroy(event.component);
  }

  onBeforeSave(entity: Nx3Entity): void {
    this.teService.onBeforeSave(entity);
  }

  onDetailInit(event: DetailEvent): void {
    if (event.component.entity.immutable) {
      event.component.getButtonById(DetailConfig.BUTTON_GROUP_TOP, 'editor').exists = false;
    }
    event.component.entity.vehicleLicence.download.contextString = '';

    this.teService.createReleaseVehicleButton(event.component);
  }

}
