import {
  AbstractNx3ClientConfig,
  Nx3Client,
  SyncStorageType,
} from '@nx3/nx3-client';

export class Nx3ClientConfig extends AbstractNx3ClientConfig {
  logging = true;
  name = 'nx3_portal';
  clientId = 'portal';
  loglevel = 'trace';
  authTokenUrl = '___nx3_substitute_portal_token_url___';
  authUrl = '___nx3_substitute_portal_auth_url___';
  redirectUrl = '___nx3_substitute_portal_redirect_url___';
  anonymousAuthorities: string[] = [
    'TARGET_CREATE',
    'TARGET_METADATA',
    'TARGET_LIST',
  ];
  httpTimeout = 60000;
  servers = [{ name: 'rs1', url: '___nx3_substitute_portal_rs1_url___' }];
  userdataStorage = SyncStorageType.MEMORY;
  refreshTokenTimer = true;
}

export const environment = {
  production: false,
  nx3Client: new Nx3Client(new Nx3ClientConfig()),
};
