import { NgModule, Type } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AccessDeniedComponent,
  AuthGuard,
  BootstrapResolver,
  NetworkErrorComponent,
  PersonInvitationComponent,
  createPortalRoutes,
} from "@nx3/nx3-core-ui";
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { RedirectComponent } from './redirect/redirect.component';

// create pages that are routed within the layout
const layout: Routes = [
  {
    path: 'contact',
    component: ContactPageComponent,
    canActivate: [AuthGuard],
    resolve: {
      bootstrap: BootstrapResolver,
    },
  },
  {
    path: 'person-invitation',
    component: PersonInvitationComponent,
  },
  {
    path: 'denied/:reason',
    component: AccessDeniedComponent,
  },
  // redirect all routes starting with "!" to dashboard
  {
    matcher: (url, group, route) => {
      if (url && url[0] && url[0].path.startsWith('!')) {
        return { consumed: url, posParams: {} };
      }
      return null;
    },
    component: RedirectComponent,
  },
];
// create pages that are routed in fullscreen
const root: Routes = [
  { path: "networkerror", component: NetworkErrorComponent },
];
// patch routes to modify pathes or resolvers
const patches: Map<Type<any>, any> = new Map();

const finalRoutes = createPortalRoutes(layout, root, undefined, patches);
@NgModule({
  imports: [RouterModule.forRoot(finalRoutes, { useHash: true, enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
