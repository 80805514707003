import { Component } from '@angular/core';
import { AbstractPresentationFieldWidgetComponent, Nx3Api } from '@nx3/nx3-core-ui';

@Component({
  selector: 'lib-infopopover',
  templateUrl: './infopopover.component.html',
  styleUrls: ['./infopopover.component.scss'],
})
export class InfopopoverComponent extends AbstractPresentationFieldWidgetComponent {
  constructor(public nx3: Nx3Api) {
    super(nx3);
  }
}
