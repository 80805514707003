import { DetailConfig, Nx3Api } from '@nx3/nx3-core-ui';
import { AbstractPortalExtension } from './abstract-portal-extension';

export class DepartmentExtension extends AbstractPortalExtension {

  constructor(public nx3: Nx3Api) {
    super('department', nx3);
  }

  configureDetail(config: DetailConfig): void {
    super.configureDetail(config);
    config.enableAuditLog = false;
    config.enableList = false;
    config.header.visible = false;
  }

}
