import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { Nx3CmsModule, Nx3ComponentsModule, Nx3QualificationModule } from '@nx3/nx3-core-ui';
import { HighchartsChartModule } from 'highcharts-angular';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { InfopopoverComponent } from './infopopover/infopopover.component';
import { LiabilityamountUserTaskDescriptionComponent } from './ins-sub-contr-transp-usertask-description/liabilityamount-usertask-description.component';
import { MinwageUserTaskDescriptionComponent } from './minwage-usertask-description/minwage-usertask-description.component';
import { MissingPropertiesModalComponent } from './missing-properties-modal/missing-properties-modal.component';
import { QualificationsCurrentOrgComponent } from './qualifications-current-org/qualifications-current-org';
import { SelectDepartmentFieldComponent } from './select-department-field/select-department-field.component';
import { SelectDepartmentComponent } from './select-department/select-department.component';
import { VehicleTypeModalComponent } from './vehicle-type-modal/vehicle-type-modal.component';
import { ChangedRelevantFieldsModalComponent } from './changed-relevant-fields-modal/changed-relevant-fields-modal.component';
import { ConfirmTeTypeModalComponent } from './confirm-te-type-modal/confirm-te-type-modal.component';
import { DisabledFieldComponent } from './disabled-field/disabled-field.component';
import { CarrierRelationTableMapComponent } from './carrier-relation-table-map/carrier-relation-table-map.component';
import { ValidityWarningComponent } from './validity-warning/validity-warning.component';
import { MultiDocumentPreviewFieldComponent } from './multi-document-preview-field/multi-document-preview-field.component';

@NgModule({
  providers: [DatePipe],
  declarations: [
    CarrierRelationTableMapComponent,
    ChangedRelevantFieldsModalComponent,
    ConfirmTeTypeModalComponent,
    DisabledFieldComponent,
    InfopopoverComponent,
    LiabilityamountUserTaskDescriptionComponent,
    MinwageUserTaskDescriptionComponent,
    MissingPropertiesModalComponent,
    QualificationsCurrentOrgComponent,
    SelectDepartmentComponent,
    SelectDepartmentFieldComponent,
    ValidityWarningComponent,
    VehicleTypeModalComponent,
    MultiDocumentPreviewFieldComponent,
  ],
  imports: [
    CommonModule,
    HighchartsChartModule,
    Nx3ComponentsModule,
    Nx3CmsModule,
    Nx3QualificationModule,
    TypeaheadModule
  ],
  exports: [
    CarrierRelationTableMapComponent,
    ChangedRelevantFieldsModalComponent,
    ConfirmTeTypeModalComponent,
    DisabledFieldComponent,
    InfopopoverComponent,
    LiabilityamountUserTaskDescriptionComponent,
    MinwageUserTaskDescriptionComponent,
    MissingPropertiesModalComponent,
    SelectDepartmentFieldComponent,
    ValidityWarningComponent,
    VehicleTypeModalComponent,
  ]
})
export class SharedModule { }
