import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Nx3Entity, ResourceConfig, ResourceModel, Nx3BeanModel } from '@nx3/nx3-client';
import {
  AbstractPageComponent,
  DetailPageComponent,
  ExtensionService,
  NavItem,
  Nx3Api,
} from "@nx3/nx3-core-ui";
import { take } from "rxjs";

@Component({
  templateUrl: "./contact-page.component.html",
  styleUrls: ["./contact-page.component.scss"],
})
export class ContactPageComponent extends DetailPageComponent {
  department: Nx3Entity;
  departmentResourceConfig: ResourceConfig;
  departmentResourceModel: ResourceModel;
  departmentMetadata: Nx3BeanModel;
  employees: Nx3Entity[] = [];
  employeeResourceConfig: ResourceConfig;
  employeeResourceModel: ResourceModel;
  employeeMetadata: Nx3BeanModel;
  configOverride;
  breadcrumbs: NavItem[] = [];

  constructor(nx3: Nx3Api, route: ActivatedRoute, extensions: ExtensionService) {
    super(nx3, route, extensions);
    this.breadcrumbs.push(NavItem.createSimple('nx3_label_dashboard', 'tachometer', '/dashboard'));
    this.breadcrumbs.push(NavItem.createSimple('nx3_label_contact', 'user', '/contact'));
  }

  bindResolves() {
    super.bindResolves();

    const server = this.nx3.config.registrationServer;
    const departmentRef = this.route.snapshot.data.bootstrap[2].organization.roles[0];

    this.nx3.client.resource.resourceModelByClassName(server, departmentRef.type).subscribe((resourceModel: ResourceModel) => {
      this.departmentResourceModel = resourceModel;
      this.nx3.client.entity.resourceConfigByName(server, resourceModel.name).subscribe((resourceConfig: ResourceConfig) => {
        this.departmentResourceConfig = resourceConfig;
        this.nx3.client.metadata.get(server, resourceModel).subscribe((metadata: Nx3BeanModel) => {
          this.departmentMetadata = metadata;
          this.nx3.client.entity.findOne(resourceConfig, departmentRef.referenceId).pipe(take(1)).subscribe((department) => {
            this.department = department;
          });
        });
      });
    });
    this.nx3.client.resource.resourceModelByClassName(server, 'de.seriea.nx3.core.businesspartner.Employee').subscribe((resourceModel: ResourceModel) => {
      this.employeeResourceModel = resourceModel;
      this.nx3.client.entity.resourceConfigByName(server, resourceModel.name).subscribe((resourceConfig: ResourceConfig) => {
        this.employeeResourceConfig = resourceConfig;
        this.nx3.client.metadata.get(server, resourceModel).subscribe((metadata: Nx3BeanModel) => {
          this.employeeMetadata = metadata;
          this.nx3.client.rest.get(server, '/api/department/references/de.seriea.nx3.core.businesspartner.Employee/' + departmentRef.referenceId)
          .pipe(take(1)).subscribe((employees) => {
            this.employees = employees;
          });
        });
      });
    });
  }
}
