import { Component, forwardRef, Input, Output } from '@angular/core';
import {
  ControlValueAccessor, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, UntypedFormControl,
  UntypedFormGroup, ValidationErrors, Validators
} from '@angular/forms';
import { Nx3Api } from '@nx3/nx3-core-ui';

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectDepartmentComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectDepartmentComponent),
      multi: true,
    },
  ],
  selector: 'lib-select-department',
  templateUrl: './select-department.component.html',
  styleUrls: ['./select-department.component.scss'],
})
export class SelectDepartmentComponent implements ControlValueAccessor {
  @Input() step: number;
  @Input() cssClass: string;
  @Input() autocomplete: string;
  @Input() placeholder: string;
  @Input() readonly: boolean;
  @Input() required: boolean;
  @Input() name: string;
  @Input() id: string;
  @Input() options: any[];

  departmentForm: UntypedFormGroup = new UntypedFormGroup({
    id:new UntypedFormControl(null),
    key:new UntypedFormControl(null),
    description:new UntypedFormControl('', Validators.required)
  });

  @Output() propagateChange = (_: any) => {};
  @Output() propagateTouch = () => {};

  constructor(public nx3: Nx3Api) {}

  validate(): ValidationErrors {
    return this.departmentForm.get('description')?.errors;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.departmentForm.patchValue(obj);
      this.propagateChange(this.departmentForm.value);
      this.propagateTouch();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  onChange(event) {
    if(!event) {
      return;
    }
    if(event.item && this.departmentForm.get('description').value !== event.item.description) {
      this.departmentForm.reset();
    } else {
      this.departmentForm.patchValue(event.item);
    }
    this.propagateChange(this.departmentForm.value);
    this.propagateTouch();
  }

}
