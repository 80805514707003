import { Component, Input, OnInit } from '@angular/core';
import { AbstractEntityComponent, ExtensionService, Nx3Api } from '@nx3/nx3-core-ui';
import { Nx3FieldModel, Nx3Entity } from '@nx3/nx3-client';
import { take } from 'rxjs';

@Component({
  selector: 'lib-liabilityamount-usertask-description',
  templateUrl: './liabilityamount-usertask-description.component.html',
  styleUrls: ['./liabilityamount-usertask-description.component.scss'],
})
export class LiabilityamountUserTaskDescriptionComponent extends AbstractEntityComponent implements OnInit {

  @Input() documentType: string;
  textBefore: string;
  textAfter: string;
  documentTemplateDownload: Nx3FieldModel;
  liabilityamounts: Nx3Entity[];

  constructor(public nx3: Nx3Api, protected extensions: ExtensionService) {
    super(nx3, extensions)
  }

  ngOnInit(): void {
    this.documentTemplateDownload = this.metadata.getFieldByName('documentTemplateDownload');
    if(this.nx3.i18n.hasTranslation('nx3_label_liability_amount_' + this.documentType + '_text')) {
      this.textBefore = this.nx3.i18n.translate.instant(
        'nx3_label_liability_amount_' + this.documentType + '_text',
        this.entity
      );
    }
    if(this.nx3.i18n.hasTranslation('nx3_label_liability_amount_' + this.documentType + '_text2')) {
      this.textAfter = this.nx3.i18n.translate.instant(
        'nx3_label_liability_amount_' + this.documentType + '_text2',
        this.entity
      );
    }

    this.nx3.client.rest.get(this.nx3.config.registrationServer, '/api/liabilityamount/findby/' + this.documentType).pipe(take(1)).subscribe((liabilityamounts: Nx3Entity[]) => {
      this.liabilityamounts = liabilityamounts;
    });
  }
}
