<div class="card">
  <div class="card-body p-2 pb-0">
    <p class="text-wrap" [innerHtml]="'nx3_task_provideMinWageDocumentsUserTask_description_miLoG_part_1' | translate | safe: 'html'"></p>
    <p class="text-wrap" [innerHtml]="'nx3_task_provideMinWageDocumentsUserTask_description_miLoG_part_2' | translate | safe: 'html'"></p>
    <div class="col-4 pb-3">
      <nx3-form-field
        [object]="entity"
        [metadata]="metadata"
        [field]="docTemplateSelfDeclarationDownloadField"
        [resourceConfig]="resourceConfig"
      ></nx3-form-field>
    </div>
    <div class="col-4 pb-3">
      <nx3-form-field
        [object]="entity"
        [metadata]="metadata"
        [field]="docTemplateSelfDeclarationIntDownloadField"
        [resourceConfig]="resourceConfig"
      ></nx3-form-field>
    </div>
    <div class="col-4 pb-3">
      <nx3-form-field
        [object]="entity"
        [metadata]="metadata"
        [field]="docTemplateCertOfTheTaxAdvisorDownloadField"
        [resourceConfig]="resourceConfig"
      ></nx3-form-field>
    </div>
  </div>
</div>
