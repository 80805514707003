import { Nx3SearchFilter } from '@nx3/nx3-client';
import { ListConfig, Nx3Api, Nx3UserTaskExtension } from '@nx3/nx3-core-ui';


export class OperativeUserTaskExtension extends Nx3UserTaskExtension {
  constructor(public nx3: Nx3Api, resourceName = 'operativeusertask') {
    super(nx3, resourceName);
  }

  onBeforeSearch(filter: Nx3SearchFilter): void {
    filter.contextStore = {'taskswithoutoperative': false};
  }

  configureList(config: ListConfig): void {
    super.configureList(config);
    config.filter.hiddenFilterFields.push('filterType');
  }
}
