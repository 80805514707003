import { ListConfig, ListEvent, Nx3Api } from '@nx3/nx3-core-ui';
import { VehicleTypeModalComponent } from 'projects/shared/src/lib/vehicle-type-modal/vehicle-type-modal.component';
import { AbstractPortalExtension } from './abstract-portal-extension';
import { Orientation } from 'ngx-guided-tour';

export class TransportEquipmentListEntryExtension extends AbstractPortalExtension {

  constructor(public nx3: Nx3Api, public resourceName = 'treqlistentry') {
    super(resourceName, nx3);
  }

  configureList(config: ListConfig): void {
    super.configureList(config);
    config.enableDuplicate = true;
    config.enableGuidedTour = true;

    config.filter.hiddenFilterFields.push('transportEquipmentOperationsType', 'temperatureZoneType', 'drivingForOwnDepartment', 'displayRemoved', 'displayBlocked');

    config.handlerCreate = () => {
      this.nx3.modal.component(
        VehicleTypeModalComponent.asModal(null)
      );
    };
  }

  onListInit(event: ListEvent): void {
    event.component.config.guidedTour = this.getGuidedTour();
  }

  getGuidedTour() {
    return {
      tourId: 'list',
      useOrb: false,
      skipText:'ddd',

      steps: [
        {
          selector: 'nx3-table',
          content: this.nx3.i18n.translate.instant('intro_tr_eq_list'),
          orientation: Orientation.Bottom,
        },
        {
          selector: 'nx3-search-filter',
          content: this.nx3.i18n.translate.instant('intro_tr_eq_search'),
          orientation: Orientation.Bottom,
        },
        {
          selector: '#create',
          content: this.nx3.i18n.translate.instant('intro_tr_eq_create'),
          orientation: Orientation.Bottom,
        },
      ],
    };
  }

}
