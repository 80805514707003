import { EditorEvent, FormBehavior, Nx3Api } from '@nx3/nx3-core-ui';
import { TransportEquipmentExtension } from './transport-equipment-extension';
import { Validators } from '@angular/forms';


export class TruckOver7k5Extension extends TransportEquipmentExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3, 'truckover7k5');
  }

  onEditorInit(event: EditorEvent): void {
    super.onEditorInit(event);
    const superFormBehavior = event.component.config.form.formBehavior;
    event.component.config.form.formBehavior = (behavior: FormBehavior) => {
      superFormBehavior(behavior);

      behavior
        .when('fuelType', (object: any) => {
          return object.fuelType !== 'ELECTRO';
        })
        .setHidden('fuelConsumption', false)
        .setValidators('fuelConsumption', [Validators.required, Validators.min(10), Validators.max(40)])
    };
  }
}
