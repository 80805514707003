import { Component, OnInit, OnDestroy } from '@angular/core';
import { Nx3SearchComposition, Nx3SearchCriteria, Nx3SearchFilter, ResourceConfig, ResourceModel } from '@nx3/nx3-client';
import { ColorService, ContentPanelConfig, GeoCoordinate, Map, Nx3Api, RegionComponent } from '@nx3/nx3-core-ui';
import { take, zip } from 'rxjs';

@Component({
  selector: 'app-carrier-relation-table-map',
  templateUrl: './carrier-relation-table-map.component.html',
  styleUrls: ['./carrier-relation-table-map.component.scss'],
})
export class CarrierRelationTableMapComponent extends RegionComponent implements OnInit, OnDestroy {
  treeElements: any[];
  tree: any;
  mapShown = false;
  panelConfig: ContentPanelConfig;

  constructor(public nx3: Nx3Api, public colorService: ColorService) {
    super(nx3, colorService);
  }


  ngOnDestroy(): void {
    if(this.map) {
      super.ngOnDestroy();
    }
  }

  ngOnInit(): void {
    this.panelConfig = new ContentPanelConfig('map', 'nx3_label_map', 'p-3')
    this.nx3.client.rest.get(this.nx3.config.registrationServer, '/api/region/tree').pipe(take(1)).subscribe((tree: any) => {
      this.tree = tree;
      this.treeElements = [];
      let lastRegion = null;
      for (const parentRegion in tree) {
        for (let j = 0; j < tree[parentRegion].length; j++) {
          if (lastRegion === parentRegion) {
              this.treeElements.push({ region: '', subregion: tree[parentRegion][j] })
          } else {
              this.treeElements.push({ region: parentRegion, subregion: tree[parentRegion][j] })
          }
          lastRegion = parentRegion;
        }
      }
    },
    (error: any) => {
      console.error("Error loading regions: " + error.response?.data?.message);
    });
  }

  getSubregionCount(region) {
    return this.tree[region].length;
  }

  showMap() {
    this.mapShown = true;
    this.nx3.spinner.show();
    this.nx3.client.resource
    .resourceModelByName(this.nx3.config.registrationServer, 'nx3region')
    .pipe(take(1))
    .subscribe((resourceModel: ResourceModel) => {
      const resourceConfig = ResourceConfig.forResourceModel(
        this.nx3.config.registrationServer,
        resourceModel
      );
      const filter: Nx3SearchFilter = new Nx3SearchFilter();
      filter.pageSize = 9999;
      filter.criteria = new Nx3SearchCriteria();
      filter.criteria.composition = Nx3SearchComposition.AND;
      filter.criteria.criteria = [Nx3SearchCriteria.andEq('classification', ['department'])];
      this.nx3.client.entity.filterSearch(resourceConfig, filter).pipe(take(1)).subscribe((regsionList) => {
        const observables = [];
        for(const region of regsionList.content) {
          observables.push(this.nx3.client.entity.findOne(resourceConfig, region.id));
        }
        zip(observables).pipe(take(1)).subscribe((regions) => {
          this.nx3.geo.initializeGeoService().subscribe(() => {
            setTimeout(() => {
              const areaColor = this.colorService.getCSSVariableValue('--nx3-color-secondary')
              this.map = this.nx3.geo.createMap(this.mapId);
              for(const region of regions) {
                if(region.area) {
                  region.area.filled = true;
                  region.area.color = areaColor;
                  this.nx3.geo.createGeometryObject(this.map, region.area);
                  if (region.centerPointLat && region.centerPointLon) {
                    const centerPoint: GeoCoordinate = {
                      lat: region.centerPointLat,
                      lng: region.centerPointLon,
                    };
                    const icon = this.nx3.geo.createMapIcon(
                      this.colorService.getCSSVariableValue('--nx3-color-secondary'),
                      'location',
                      0.03
                    );
                    this.createMarker(this.map, centerPoint, icon, 'black', region.displayString);
                  }
                }
              }
              this.nx3.spinner.hide();
              this.nx3.geo.fitToBounds(this.map);
            });
          });
        });
      });
    });
  }

  createMarker(
    map: Map,
    coordinate: GeoCoordinate,
    icon?: any,
    color?: string,
    label?: string
  ): any {
    if (!color) {
      color = '--nx3-color-secondary';
    }

    color = this.colorService.value(color);

    if (!icon) {
      icon = this.nx3.geo.createMapIcon(color, 'location');
    }

    const marker = new google.maps.Marker({
      position: { lat: coordinate.lat, lng: coordinate.lng },
      map: map.map,
      /* animation: google.maps.Animation.DROP, */
      icon,
      label: {text: label, color: '#ffffff', fontSize: '12px'},
    });

    map.markers.push(marker);
    return marker;
  }

}
