<div [formGroup]="departmentForm">
  <div class="input-group input-group-sm">
    <input
      class="form-control form-control-sm"
      type="text"
      name="department"
      [required]="required"
      formControlName="description"
      [typeaheadMinLength]="0"
      [typeahead]="options"
      [typeaheadOptionsLimit]="options.length"
      typeaheadScrollable="true"
      typeaheadOptionsInScrollableView="15"
      typeaheadOptionField="description"
      (typeaheadOnSelect)="onChange($event)"
      (typeaheadOnBlur)="onChange($event)"
      placeholder="{{'nx3_label_userdefineddepartment_typeahead' | translate}}"
     />
  </div>
</div>
