import { DetailConfig, DocumentsShareExtension, DynamicComponentConfig, FormlayoutComponent, ListConfig, ListEvent, Nx3Api, nestedConfig } from '@nx3/nx3-core-ui';
import { DocShareCategoryFilterComponent } from '../components/doc-share-category-filter/doc-share-category-filter.component';

export class CustomDocumentsShareExtension extends DocumentsShareExtension {

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  configureList(config: ListConfig): void {
    config.setFixedView(false);
    config.table.toolDetail = false;
    config.enableCount = false;
    config.enableDuplicate = false;
    config.enableListSettings = false;
    config.enableGuidedTour = false;
    config.enableNotifications = false;
    config.pagination.pageSizes = [10, 25, 50, 75, 100];
    config.enableFilterModeToggle = false;

    config.componentExtensions.addNorthComponent(new DynamicComponentConfig(
      DocShareCategoryFilterComponent
    ));
  }

  configureDetail(config: DetailConfig): void {
    config.enableNotifications = false;
    config.enableDuplicate = false;

    config.layout.rootLayout()
    .row()
    .nestedColumn(12)
    .component(FormlayoutComponent,
      nestedConfig(false)
      .mapModel('entity', 'object')
    )
    .up();
  }

  onListDestroy(event: ListEvent): void {
    this.nx3.client.events.unregister('DOC_SHARE_FILTER', this);
  }

  onListInit(event: ListEvent): void {
    this.nx3.client.events.on('DOC_SHARE_FILTER', (docShareEvent: any) => {
      if(docShareEvent.data.category) {
        event.component.filter.contextStore.category = docShareEvent.data.category;
        event.component.config.filter.hiddenFilterFields.push('modelExtension.categoryKey');
        event.component.metadata.getColumnByName('modelExtension.categoryKey').visible = false;
      } else {
        event.component.filter.contextStore.category = null;
        event.component.config.filter.hiddenFilterFields = [];
        event.component.metadata.getColumnByName('modelExtension.categoryKey').visible = true;
      }

      event.component.filterComponent.initFilterFields();
      event.component.search();
    }, this);
  }
}
