<div class="primary-color">
  <span
    class="nx3-clickable"
    [ngbPopover]="popContent"
    [popoverTitle]="params[0] | translate"
    container="body"
    placement="right bottom top left"
    popoverClass="custom-popover-class"
  >
    <nx3-icon class="pe-1" icon="info-circle"></nx3-icon>
    <span class="control-label" translate="nx3_label_field_entry_help"></span>
  </span>
</div>

<ng-template #popContent>
  <div>
    <span [innerHtml]="params[1] | translate | safe: 'html'"></span>
    <div class="pt-3" *ngIf="params[2]">
      <nx3-mediafile [name]="params[2]"></nx3-mediafile>
    </div>
  </div>
</ng-template>
