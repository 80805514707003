import { DetailConfig, DetailEvent, EntityDataQualityComponent, ListConfig, Nx3Api, QualificationExtension, ReferenceListConfig } from '@nx3/nx3-core-ui';
import { BehaviorSubject } from 'rxjs';

export class CustomQualificationExtension extends QualificationExtension {

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  configureList(config: ListConfig): void {
    super.configureList(config);
    config.buttons = config.buttons.filter((b) => b.buttonModel.id !== 'create_forbidden')
    if(!this.nx3.client.auth.hasSecurityGroup('DEPARTMENT_GROUP')) {
      config.filter.hiddenFilterFields.push('ownCarrier');
    }
  }

  onDetailAfterView(event: DetailEvent): void {
    super.onDetailAfterView(event);
    event.component.getButtonById(DetailConfig.BUTTON_GROUP_TOP, 'cancel').withExists(new BehaviorSubject(false));
    event.component.getButtonById(DetailConfig.BUTTON_GROUP_TOP, 'forbid').withExists(new BehaviorSubject(false));
  }

  onDetailInit(event: DetailEvent): void {
    super.onDetailInit(event);
    const config = new ReferenceListConfig();
    config.enableCreate = false;
    config.enableSelection = false;
    if(event.component.referencingModels) {
      for(const ref of event.component.referencingModels) {
        this.referenceListConfig.set(ref.resourceModel.name, config);
      }
    }
  }

  configureDetail(config: DetailConfig): void {
    super.configureDetail(config);
    config.disabledComponents = [EntityDataQualityComponent];
    this.detailLayout().createDefaultLayout(this.nx3, config, config.layout.rootLayout());
    config.layout.rootLayout().rows[1].columns[0].config.width = 12;
  }

}
