import {
  DetailConfig,
  FormlayoutComponent, ListConfig, Nx3Api,
  ResourceExtension,
  nestedConfig
} from '@nx3/nx3-core-ui';

/**
 * Abstraction for resource extensions used in portal
 */
export abstract class AbstractPortalExtension extends ResourceExtension {

  constructor(resourceName: string, public nx3: Nx3Api) {
    super(resourceName, nx3);
  }

  configureList(config: ListConfig): void {
    config.setFixedView(false);
    config.table.toolDetail = false;
    config.enableCount = false;
    config.enableDuplicate = false;
    config.enableListSettings = false;
    config.enableGuidedTour = false;
    config.enableNotifications = false;
    config.pagination.pageSizes = [10, 25, 50, 75, 100];
  }

  configureDetail(config: DetailConfig): void {
    config.enableNotifications = false;
    config.enableDuplicate = false;

    config.layout.rootLayout()
    .row()
    .nestedColumn(12)
    .component(FormlayoutComponent,
      nestedConfig(false)
      .mapModel('entity', 'object')
    )
    .up();
  }

}
