import {
  Nx3BeanModel,
  Nx3DataType,
  Nx3FieldModel,
  Nx3FormSection,
} from "@nx3/nx3-client";
import { DetailEvent, Nx3Api } from "@nx3/nx3-core-ui";
import { CustomVerificationDecisionUserTaskExtension } from "projects/shared/src/lib/resource-extension/custom-verification-decision-usertask-extension";
import { MultiDocumentPreviewFieldComponent } from "../multi-document-preview-field/multi-document-preview-field.component";

export class CustomMultiDocumentVerificationTaskExtension extends CustomVerificationDecisionUserTaskExtension {
  documentFieldNames: string[];

  sectionsToAdd: Map<number, string> = new Map<number, string>();

  constructor(public nx3: Nx3Api, taskname: string, docFieldNames: string[]) {
    super(nx3, taskname);
    this.documentFieldNames = docFieldNames;
  }

  onDetailInit(event: DetailEvent): void {
    for (let i = 0; i < event.component.metadata.form.sections.length; i++) {
      const section = event.component.metadata.form.sections[i];
      // search for document field in section
      const documentFieldName = this.sectionHasDocumentField(
        event.component.metadata,
        section
      );
      if (documentFieldName !== null) {
        this.sectionsToAdd.set(i, documentFieldName);
      }
    }

    // add document preview sections
    let offset = 0;
    for (const [index, documentFieldName] of this.sectionsToAdd) {
      this.addDocumentPreviewSection(
        event.component.metadata,
        documentFieldName,
        index + offset
      );
      offset++;
    }

    if(event.component.entity.hideValidity) {
      for(const documentFieldName of this.documentFieldNames) {
        event.component.metadata.getFieldByName(documentFieldName + '.validFrom').hidden = true;
        event.component.metadata.getFieldByName(documentFieldName + '.validTo').hidden = true;
      }
    }
  }

  addDocumentPreviewSection(
    metadata: Nx3BeanModel,
    documentFieldName: string,
    index: number
  ) {
    const section: Nx3FormSection = metadata.addFormSection(
      "section_preview",
      index
    );

    const field: Nx3FieldModel = new Nx3FieldModel();
    field.name = documentFieldName + "_preview";
    field.labelHidden = true;
    field.type = Nx3DataType.COMPONENT;
    field.span = 2;
    field.componentType = MultiDocumentPreviewFieldComponent;
    metadata.addField(field);

    section.rows.push({
      fields: [field.name],
    });
  }

  sectionHasDocumentField(
    metadata: Nx3BeanModel,
    section: Nx3FormSection
  ): string {
    for (const row of section.rows) {
      for (let fieldName of row.fields) {
        const field = metadata.getFieldByName(fieldName);

        // check if field name includes document field name.
        for (const documentFieldName of this.documentFieldNames) {
          if (fieldName.includes(documentFieldName)) {
            return documentFieldName;
          }
        }
      }
    }
    return null;
  }
}
