<nx3-content-panel [config]="panelConfig">
  <div class="row" *ngIf="treeElements;else noData">
    <div class="col-3">
      <table class="table">
        <thead>
          <th translate="section_location"></th>
          <th translate="property_region_label"></th>
        </thead>
        <tbody>
          <tr *ngFor="let element of treeElements">
            <td style="width: 50%">
              {{element.subregion}}
            </td>
            <td style="width: 50%" class="region-column" *ngIf="element.region" [attr.rowspan]="getSubregionCount(element.region)">
              <strong>{{element.region}}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-9">
      <button class="btn btn-md btn-primary" (click)="showMap()" *ngIf="!mapShown">
        <i class="fa fa-map"></i> {{ 'nx3_label_show_map' | translate }}
      </button>
      <div id="{{mapId}}" style="height:500px" class="nx3-fade-in border" *ngIf="mapShown"></div>
    </div>
  </div>
</nx3-content-panel>

<ng-template #noData>
  <nx3-no-data></nx3-no-data>
</ng-template>
