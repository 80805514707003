import { Injectable } from '@angular/core';
import { Nx3Entity, ResourceConfig, ResourceModel } from '@nx3/nx3-client';
import { ButtonModel, ButtonRegistration, CommonNames, DetailComponent, DetailConfig, EditorComponent, EntitySelectionCommand, FormBehavior, Nx3Api, SelectionType } from '@nx3/nx3-core-ui';
import { BehaviorSubject, Observable, flatMap, map, of, take } from 'rxjs';
import { ChangedRelevantFieldsModalComponent } from '../changed-relevant-fields-modal/changed-relevant-fields-modal.component';
import { MissingPropertiesModalComponent } from '../missing-properties-modal/missing-properties-modal.component';
import { DocumentUploadService } from './document-upload.service';


/**
 * Date and datetime utils used by components
 *
 * @author martin.bueltel(at)serie-a.de
 */
@Injectable({
  providedIn: 'root',
})
export class TransportEquipmentService {
  server: string;

  constructor(public nx3: Nx3Api, public documentUploadService: DocumentUploadService) {
    this.server = this.nx3.config.registrationServer;
  }

  createAssignDepartmentButton(component: DetailComponent) {
    component.registerButton(
      new ButtonRegistration(
        new ButtonModel(
          'nx3_button_assign_department',
          CommonNames.DETAIL,
          (event: any) => {
            const model = component.getModel();
            this.nx3.client.resource
            .resourceModelByName(this.server, 'department')
            .pipe(take(1))
            .subscribe((resourceModel: ResourceModel) => {
              const resourceConfig = ResourceConfig.forResourceModel(
                this.server,
                resourceModel
              );
              this.nx3.modal.selectEntity(
                new EntitySelectionCommand(
                  resourceConfig,
                  resourceModel,
                  SelectionType.SINGLE,
                  (selection: Nx3Entity[]) => {
                    this.nx3.client.rest.post(
                      this.server,
                      '/api/transportequipment/' + component.entity.id + '/assign/' + selection[0].id,
                      {}
                    )
                    .pipe(take(1))
                    .subscribe(() => {
                      this.nx3.navigation.reload()
                      this.nx3.toast.success('nx3_label_assign_department_success');
                    });
                  }
                )
              );
            });
          }
        )
        .withOverflow(false)
        .withIcon('building')
        .withTooltip('nx3_button_assign_department')
        .withExists(
          new BehaviorSubject(
            !component.entity.immutable &&
            this.nx3.client.auth.hasAuthority('TRANSPORTEQUIPMENT_ASSIGN_DEPARTMENT')
          )
        ),
        DetailConfig.BUTTON_GROUP_TOP
      )
    );
  }

  createReleaseVehicleButton(component: DetailComponent) {
    component.registerButton(
      new ButtonRegistration(
        new ButtonModel(
          'nx3_button_release_vehicle',
          CommonNames.DETAIL,
          (event: any) => {
            this.nx3.spinner.show();
            const url = '/api/transportequipment/' + component.entity.id + '/release';
            this.nx3.client.rest.post(this.server, url + '/dry', {}).pipe(take(1)).subscribe((response) => {
              if (response.missingProperties.length > 0) {
                this.nx3.spinner.hide();
                this.nx3.modal.component(
                  MissingPropertiesModalComponent.asModal(response.missingProperties)
                );
              } else {
                this.nx3.client.rest.post(this.server, url, {}).pipe(take(1)).subscribe(() => {
                  this.nx3.spinner.hide();
                  this.nx3.navigation.reload()
                  this.nx3.toast.success('nx3_label_release_vehicle_success');
                });
              }
            });
          }
        )
        .withOverflow(false)
        .withIcon('check')
        .withTooltip('nx3_button_release_vehicle')
        .withExists(
          new BehaviorSubject(
    	      !component.entity.immutable &&
            this.nx3.client.auth.hasResourceAuthority(
              component.resourceModel.authority,
              'UPDATE'
            ) &&
            component.entity.state &&
            (component.entity.state.key === 'NEW' || component.entity.state.key === 'DRAFT')
          )
        ),
        DetailConfig.BUTTON_GROUP_TOP
      )
    );
  }

  transportEquipmentFormBehavior(editorComponent: EditorComponent, behavior: FormBehavior) {
    if(editorComponent.isNew()) {
      behavior
        .global((object: any) => true)
        .setSectionVisible('section_missing_req_docs', false);
    }

    behavior
      .global(() => true)
      .setRequired('vehicleLicence.name', true)
      .run((object: any) => {
        this.documentUploadService.registerDropzoneEvents(editorComponent);
      });

    behavior
      .global((object: any) => !object.vehicleLicence.language)
      .setStaticValue('vehicleLicence.language', this.nx3.client.i18n.getLanguage());

    behavior
      .when('refrigerationSystem', (object: any) => object.refrigerationSystem?.referenceId)
      .run((object: any) => {
        this.nx3.client.rest.get(this.server, '/api/refrigerationsystem/' + object.refrigerationSystem?.referenceId).pipe(take(1)).subscribe((result) => {
          editorComponent.form.get('powerZeroDeg').setValue(result.refrigerationSystemPower.powerZeroDeg);
          editorComponent.form.get('powerMinusTtyDeg').setValue(result.refrigerationSystemPower.powerMinusTtyDeg);
        });
      });

    behavior
      .when('liftgateStanding', (object: any) => object.liftgateStanding)
      .setHidden('payloadLiftgateInKG', false)
      .setRequired('payloadLiftgateInKG', true);

    behavior
      .when('liftgate', (object: any) => object.liftgate)
      .setHidden('payloadLiftgateInKG', false)
      .setRequired('payloadLiftgateInKG', true);

    behavior
      .when('liftgateStanding', (object: any) => !object.liftgate && !object.liftgateStanding)
      .setRequired('payloadLiftgateInKG', false)
      .setHidden('payloadLiftgateInKG', true);

    behavior
      .when('liftgate', (object: any) => !object.liftgate && !object.liftgateStanding)
      .setRequired('payloadLiftgateInKG', false)
      .setHidden('payloadLiftgateInKG', true);

    behavior
      .when('maxWeightInKG', (object: any) => {
        return object.maxWeightInKG && object.tareWeightInKG;
      })
      .setValue('payloadInKG', (object: any) => {
        return object.maxWeightInKG - object.tareWeightInKG;
      })
      .markAsTouched('payloadInKG');

    behavior
      .when('tareWeightInKG', (object: any) => {
        return object.maxWeightInKG && object.tareWeightInKG;
      })
      .setValue('payloadInKG', (object: any) => {
        return object.maxWeightInKG - object.tareWeightInKG;
      })
      .markAsTouched('payloadInKG');

    behavior
      .when('fuelType', (object: any) => {
        return object.fuelType === 'ELECTRO';
      })
      .setStaticValue('fuelConsumption', 0)
      .clearValidators('fuelConsumption')
      .setHidden('fuelConsumption', true);
  }

  changesOnRelevantFields(component: EditorComponent) {
    if(!component.isNew()) {
      component.config.saveHandler = (entity: Nx3Entity, component: EditorComponent) => {
        // lock save button
        component.getButtonById("top", "save").disabled = true;
        this.nx3.spinner.show();

        if (entity.vehicleLicence.validFrom) {
          entity.vehicleLicence.validFrom =
            this.nx3.dates.toDateString(entity.vehicleLicence.validFrom) +
            "T00:00:00";
        }

        const url = "/api/transportequipment/changesonrelevantfields/";
        return this.nx3.client.rest
          .post(this.server, url, entity)
          .pipe(take(1))
          .pipe(
            flatMap((response) => {
              this.nx3.spinner.hide();
              const changedFields = Object.getOwnPropertyNames(response);
              if (changedFields.length > 0) {
                let fields = [];
                for (let prop of changedFields) {
                  fields.push(prop.split(".").reverse()[0]);
                }

                this.nx3.modal.component(
                  ChangedRelevantFieldsModalComponent.asModal(
                    fields,
                    //confirmed
                    () => {
                      this.save(entity, component).pipe(take(1)).subscribe();
                      component.getButtonById("top", "save").disabled = false;
                    },
                    //dismissed
                    () => {
                      component.form.markAsPristine();
                      this.nx3.navigation.reload();
                    }
                  )
                );

                return of(entity);
              }

              return this.save(entity, component);
            })
          );
      };
    } else {
      component.config.saveHandler = undefined;
    }
  }

  private save(entity: Nx3Entity, component: EditorComponent): Observable<Nx3Entity> {
    component.beforeSave();
    component.prepocessEntityForPost();
    return this.nx3.client.entity
    .save(component.resourceConfig, entity)
    .pipe(take(1))
    .pipe(
      map((saved: Nx3Entity) => {
        const isNew = !entity.id;
        entity = saved;
        component.saveSuccess();
        component.afterSave();

        const messageKey = isNew
          ? component.config.createMessageKey
          : component.config.updateMessageKey;
        if (messageKey) {
          component.handleSaveMessage(messageKey);
        } else {
          component.routeAfterSave();
        }

        return saved;
      })
    );
  }

  onEditorDestroy(component: EditorComponent): void {
    this.documentUploadService.unregisterDropzoneEvents(component);
  }

  onBeforeSave(entity: Nx3Entity): void {
    if (entity.vehicleLicence.validFrom) {
      entity.vehicleLicence.validFrom = this.nx3.dates.toDateString(entity.vehicleLicence.validFrom) + 'T00:00:00';
    }
  }
}
