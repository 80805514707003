// tslint:disable-next-line: max-line-length
import { ResourceConfig } from '@nx3/nx3-client';
import {
  ApplicationExtension,
  ComponentGridLayouts,
  ContentPanelConfig,
  DashboardConfig,
  DashboardLayoutService,
  FieldWidgetFactory,
  LayoutConfig,
  ListAdapterComponent,
  MediafileComponent,
  Nx3Api,
  Nx3ApplicationConfig,
  QualificationEventType,
  StaticHtmlContentComponent,
  newConfig
} from '@nx3/nx3-core-ui';
import { InfopopoverComponent } from 'projects/shared/src/lib/infopopover/infopopover.component';
import { QualificationsCurrentOrgComponent } from 'projects/shared/src/lib/qualifications-current-org/qualifications-current-org';
import { of } from 'rxjs';
import { PortalFooterComponent } from './components/portal-footer/portal-footer.component';


export class AppExtension extends ApplicationExtension {
  server: string;

  constructor(
    public nx3: Nx3Api,
    public fieldWidgetFactory: FieldWidgetFactory,
    public dashboardLayout: DashboardLayoutService
  ) {
    super(nx3, fieldWidgetFactory, dashboardLayout);
  }

  /**
   * Configuration callback for global settings
   * @param config
   */
  configureGlobals(config: Nx3ApplicationConfig) {
    this.server = 'rs1';
    config.pageContainerClass = "ps-2 pe-2";
    config.enableNavigationDashboard = false;
    config.enableNavigationTasks = false;
    config.enablePlaceHistoryInHeader = false;
    config.userTaskReferencesLinkEnabled = false;
    config.userTaskHiddenReferenceTypes = ['qualification'];

    config.onboardings = [
      {
        key: 'carrier',
        labelKey: 'nx3_label_registration',
        targetSubClass: 'de.seriea.nx3.core.fastonboarding.registration.RegisteredBusinessPartnerTarget',
        icon: 'truck',
        targetPreset: {
          modelType: 'de.seriea.nx3.nagel.carrier.carrier.Carrier',
        },
        onboardingSecurityGroup: 'REGISTERED_GROUP',
        descriptionContent: 'carrier-onboarding-description',
        registrationEditing: { staticContent: 'carrier-registration-editing' },
        registrationFinished: { staticContent: 'registration-mail-verify' },
        registrationConfirm: { staticContent: 'registration-mail-confirm' },
        onboardingEditing: { staticContent: 'carrier-onboarding-editing' },
        onboardingFinished: { staticContent: 'carrier-onboarding-finished' },
        verifyAfterOnboarding: true,
      },
    ];

    config.enableChartExporting = false;

    this.fieldWidgetFactory.registerPresentationType(
      'infopopover',
      InfopopoverComponent
    );
  }

  /**
   * Configuration callback for global layout
   * @param config
   */
  configureLayout(config: LayoutConfig) {
    config.header.enableNavigation = true;
    config.enableSidebar = false;
    config.header.enableOnboardings = true;
    config.header.height = 100;
    config.footer.type = PortalFooterComponent;
  }

  /**
   * Configure the dashboard page, which is available to all authenticated users
   * @param config
   */
  configureDashboard(config: DashboardConfig) {
    config.layout
      .rootLayout()
      .withPadding(3, 3)
      .withGridContainerClass("ps-3 pe-3")
      .row()
      .component(
        ListAdapterComponent,
        newConfig(12)
          .withExists(() => {
            return of(this.nx3.client.auth.hasSecurityGroup("CARRIER_GROUP"));
          })
          .withContentPanel(
            new ContentPanelConfig("", "nx3_carrier_nx3usertask_dashlet", "p-2")
          )
          .withReloadOn(this.nx3, QualificationEventType.STARTED)
          .withReloadOn(this.nx3, QualificationEventType.REACTIVATED)
          .withReloadOn(this.nx3, QualificationEventType.CANCELLED),
        { server: this.server, resource: "nx3usertask" }
      )
      .component(
        QualificationsCurrentOrgComponent,
        newConfig(12)
          .withExists(() => {
            return of(this.nx3.client.auth.hasSecurityGroup("CARRIER_GROUP"));
          })
          .withContentPanel(
            new ContentPanelConfig("", "qualification_label", "p-2")
          ),
        {
          resourceConfig: ResourceConfig.forClass(
            this.server,
            "de.seriea.nx3.nagel.carrier.carrier.Carrier"
          ),
        }
      );
  }

  /**
   * Configuration callback for the home page, which may only be relevant in portal applications
   * @param config
   */
  configureHome(home: ComponentGridLayouts) {
    home
      .rootLayout()
      .withUseContainer(false)
      .row()
      .nestedColumn(5)
      .component(StaticHtmlContentComponent, newConfig(12), {
        server: this.server,
        key: 'start_text',
      })
      .exitNesting()
      .nestedColumn(7)
      .component(MediafileComponent, newConfig(12), {
        server: this.server,
        name: 'start',
      })
      .exitNesting()
      .up()

  }
}
