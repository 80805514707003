import { FormGroup } from '@angular/forms';
import { Nx3Entity, ResourceConfig } from '@nx3/nx3-client';
import {
  CommonNames,
  ConfirmDialogCommand,
  DetailComponent,
  DynamicComponentConfig,
  EditorComponent,
  EditorEvent,
  EntityModalCommand,
  ListComponent,
  ModalComponent,
  Nx3Api,
  TaskDocumentpreviewComponent,
  VerificationDecisionUserTaskExtension,
} from "@nx3/nx3-core-ui";
import { take } from "rxjs";
import { ValidityWarningComponent } from "../validity-warning/validity-warning.component";

export class CustomVerificationDecisionUserTaskExtension extends VerificationDecisionUserTaskExtension {

  constructor(
    public nx3: Nx3Api,
    resourceName = "verificationdecisionusertask"
  ) {
    super(nx3, resourceName);
  }

  configureModal(
    list: ListComponent,
    detail: DetailComponent,
    modalInstance: ModalComponent
  ): void {
    super.configureModal(list, detail, modalInstance);

    const editor: EditorComponent = detail.componentGrids
      .getDynamics(EditorComponent)[0]
      .getInstance();
    editor.config.form.enableSectionHeaders = true;
    editor.formComponent.formChanged.subscribe((form: FormGroup) => {
      let disableButton = true;
      let acceptExists = true;
      if (editor.entity.commentary) {
        disableButton = false;
      }
      if (
        !editor.entity.verificationValidFrom &&
        !editor.entity.verificationValidTo
      ) {
        acceptExists = false;
      }

      for (const btn of modalInstance.buttons) {
        if (btn.id === "reject_task") {
          btn.disabled = disableButton;
        } else if (btn.id === "accept_task") {
          btn.exists = acceptExists;
        } else if (btn.id === "edit") {
          btn.click = (event: any) => {
            this.nx3.flash.set("userTaskContext", true);
            this.nx3.modal.entity(
              new EntityModalCommand(
                ResourceConfig.forClass(
                  editor.resourceConfig.server,
                  editor.entity.candidate.type
                ),
                editor.entity.candidate.referenceId,
                CommonNames.EDITOR,
                (entity: Nx3Entity) => {
                  this.nx3.spinner.show();

                  this.nx3.client.entity
                    .findOne(
                      editor.formComponent.resourceConfig,
                      editor.entity.id
                    )
                    .pipe(take(1))
                    .subscribe((result: Nx3Entity) => {
                      editor.entity = result;
                      editor.formComponent.object = result;
                      this.calculateValidity(editor.formComponent.object);
                      editor.formComponent.object.key = "tretretrtre";
                      editor.formComponent.form.updateValueAndValidity();
                      this.nx3.client.events.fireEvent(
                        "customVerificationEntityChanged",
                        editor.formComponent.object
                      );
                      editor.formComponent.refresh();
                      this.nx3.spinner.hide();
                    });
                }
              )
            );
          };
        }
      }
    });

    if (
      editor.entity.hideValidity ||
      editor.entity.modelExtension?.hideValidity
    ) {
      const validitySection = editor.metadata.getFormSectionByLabel(
        "section_inspection_interval"
      );
      if (validitySection) {
        validitySection.hiddenEdit = true;
        validitySection.hiddenDetail = true;
      }
    } else {

        for (const btn of modalInstance.buttons) {
          if (btn.id === 'accept_task') {
            const click = btn.click;
            btn.click = (event) => {
              if (
                this.nx3.dates
                  .now()
                  .isBefore(this.nx3.dates.parseDate(editor.entity.verificationValidFrom))
              ) {
                this.nx3.modal.confirm(
                  new ConfirmDialogCommand(
                    'nx3_label_document_valid_after_verification',
                    'nx3_message_document_valid_after_verification',
                    () => {
                      click(event);
                    },
                    () => {}
                  )
                );
              } else {
                click(event);
              }
            };
          }
        }
    }
  }

  onEditorInit(event: EditorEvent): void {
    if (
      event.component.entity.candidate.type ===
      "de.seriea.nx3.core.document.Nx3Document"
    ) {
      event.component.config.componentExtensions.addWestComponent(
        new DynamicComponentConfig(TaskDocumentpreviewComponent, null, null, [
          "d-block",
          "ms-2",
          "me-2",
          "pt-0",
          "mt-0",
        ]),
        6
      );
    }
    event.component.config.componentExtensions.addSouthComponent(
      new DynamicComponentConfig(ValidityWarningComponent)
    );
    this.calculateValidity(event.component.entity);
  }

  calculateValidity(entity: Nx3Entity) {
    const calculatedValidFrom = entity.modelExtension?.calculatedValidFrom
      ? entity.modelExtension.calculatedValidFrom
      : entity.calculatedValidFrom;
    const calculatedValidTo = entity.modelExtension?.calculatedValidTo
      ? entity.modelExtension.calculatedValidTo
      : entity.calculatedValidTo;

    entity.verificationValidFrom = calculatedValidFrom;
    entity.verificationValidTo = calculatedValidTo;
  }
}
