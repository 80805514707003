import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Nx3CmsModule, Nx3ComponentsModule } from '@nx3/nx3-core-ui';
import { SharedModule } from 'projects/shared/src/lib/shared.module';
import { ContactPageComponent } from './contact-page/contact-page.component';


/**
 * Custom components specialized for this application
 */
@NgModule({
  declarations: [
    ContactPageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    Nx3ComponentsModule,
    Nx3CmsModule,
    SharedModule
  ]
})
export class PagesModule { }
