import { Component } from '@angular/core';
import { Nx3Api } from '@nx3/nx3-core-ui';
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent {
  constructor(public nx3: Nx3Api) {
    window.location.href = '/#/';
  }
}
