import { Component } from '@angular/core';
import { Nx3Api, AbstractFieldWidgetComponent } from '@nx3/nx3-core-ui';
import { take } from 'rxjs';
import { EntityReference } from '@nx3/nx3-client';

@Component({
  selector: 'lib-select-department-field',
  templateUrl: './select-department-field.component.html',
  styleUrls: ['./select-department-field.component.scss'],
})
export class SelectDepartmentFieldComponent extends AbstractFieldWidgetComponent {
  options: any[];

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  init() {
    super.init();
    const server = this.nx3.config.registrationServer;
    const url = '/api/registeredbusinesspartnertarget/department/list';
    this.nx3.client.rest.get(server, url).pipe(take(1)).subscribe((departments) => {
      this.options = departments;
      this.options.unshift({id: null,  description: this.nx3.i18n.translate.instant('nx3_label_assign_department')});
    })
  }

  onValueChange(value: any): void {
    if(value.id) {
      const ref = new EntityReference();
      ref.referenceId = value.id;
      ref.type = 'de.seriea.nx3.nagel.carrier.department.Department';
      ref.label = value.key;
      ref.role = 'Department';
      this.object.contextStore.userDefinedDepartment = ref;
    } else {
      this.object.contextStore.userDefinedDepartment = null;
    }
  }
}
