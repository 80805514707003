import { Component } from "@angular/core";
import { AbstractFieldWidgetComponent, Nx3Api } from "@nx3/nx3-core-ui";

@Component({
  selector: "lib-multi-document-preview-field",
  templateUrl: "./multi-document-preview-field.component.html",
  styleUrls: ["./multi-document-preview-field.component.scss"],
})
export class MultiDocumentPreviewFieldComponent extends AbstractFieldWidgetComponent {
  document: any;

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  ngOnInit(): void {
    // resolve field value by dotted field name
    this.document = this.getFieldValue(
      this.object,
      this.field.name.replace("_preview", "")
    );
  }

  private getFieldValue(obj: any, fieldName: string): any {
    const fieldNames = fieldName.split(".");
    let value = obj;
    for (const name of fieldNames) {
      value = value[name];
    }
    return value;
  }
}
