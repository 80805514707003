import { Component, ComponentRef } from '@angular/core';
import { ButtonModel, ModalComponent, ModalComponentCommand } from '@nx3/nx3-core-ui';


@Component({
  selector: 'app-changed-relevant-fields-modal',
  templateUrl: './changed-relevant-fields-modal.component.html',
  styleUrls: ['./changed-relevant-fields-modal.component.scss'],
})
export class ChangedRelevantFieldsModalComponent {
  changedFields: any[];

  constructor() {}

  static asModal(
    changedFields: any[],
    confirmed: () => void,
    dismissed: () => void
  ): ModalComponentCommand {
    let modal: ModalComponent;
    let component: ChangedRelevantFieldsModalComponent;

    return new ModalComponentCommand(
      ChangedRelevantFieldsModalComponent,
      null,
      'check',
      'nx3_transport_equipment_change_modal_title',
      [
        new ButtonModel('nx3_button_cancel', 'no', (event: any) => {
          modal.dismiss(null);
        })
        .withCssClass('btn btn-sm btn-secondary')
        .withOverflow(false),

        new ButtonModel('nx3_button_save', 'yes', (event: any) => {
          modal.close(null);
        })
        .withCssClass('btn btn-sm btn-warning')
        .withOverflow(false)
      ],
      undefined,
      (m: ModalComponent) => {
        modal = m;
        m.enableCloseIcon = false;
      },
      (c: ComponentRef<any>) => {
        component = c.instance;
        component.changedFields = changedFields;
      },
      dismissed,
      confirmed,
      { size: 'md',
        backdrop: 'static'
      }
    );
  }
}
