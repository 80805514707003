<div [ngClass]="nx3.config.pageContainerClass">
  <nx3-page-breadcrumb [breadcrumbs]="breadcrumbs"></nx3-page-breadcrumb>

  <h3 class="px-3" translate="nx3_label_your_department"></h3>
  <nx3-detail
    #detail
    *ngIf="department"
    [entity]="department"
    [resourceModel]="departmentResourceModel"
    [resourceConfig]="departmentResourceConfig"
    [metadata]="departmentMetadata"
  ></nx3-detail>

  <h3 class="px-3 pt-3" translate="property_contactPersons_label"></h3>
  <nx3-detail
    #detail
    *ngFor="let employee of employees"
    [entity]="employee"
    [resourceModel]="employeeResourceModel"
    [resourceConfig]="employeeResourceConfig"
    [metadata]="employeeMetadata"
  ></nx3-detail>
</div>
