import { Component, OnInit } from '@angular/core';
import { AbstractEntityComponent, ExtensionService, Nx3Api } from '@nx3/nx3-core-ui';
import { Nx3FieldModel } from '@nx3/nx3-client';

@Component({
  selector: 'lib-minwage-usertask-description',
  templateUrl: './minwage-usertask-description.component.html',
  styleUrls: ['./minwage-usertask-description.component.scss'],
})
export class MinwageUserTaskDescriptionComponent extends AbstractEntityComponent implements OnInit {

  docTemplateSelfDeclarationDownloadField: Nx3FieldModel;
  docTemplateSelfDeclarationIntDownloadField: Nx3FieldModel;
  docTemplateCertOfTheTaxAdvisorDownloadField: Nx3FieldModel;

  constructor(public nx3: Nx3Api, protected extensions: ExtensionService) {
    super(nx3, extensions)
  }

  ngOnInit(): void {
    this.docTemplateSelfDeclarationDownloadField = this.metadata.getFieldByName('docTemplateSelfDeclarationDownload');
    this.docTemplateSelfDeclarationIntDownloadField = this.metadata.getFieldByName('docTemplateSelfDeclarationIntDownload');
    this.docTemplateCertOfTheTaxAdvisorDownloadField = this.metadata.getFieldByName('docTemplateCertOfTheTaxAdvisorDownload');
  }
}
