import { Component, ComponentRef } from '@angular/core';
import {
  ButtonModel, ModalComponent,
  ModalComponentCommand,
  Nx3Api
} from '@nx3/nx3-core-ui';


@Component({
  selector: 'app-confirm-te-type-modal',
  templateUrl: './confirm-te-type-modal.component.html',
  styleUrls: ['./confirm-te-type-modal.component.scss'],
})
export class ConfirmTeTypeModalComponent {

  type: string;

  constructor(public nx3: Nx3Api) {}

  static asModal(
    type: string,
    confirmed: () => void
  ): ModalComponentCommand {
    let modal: ModalComponent;
    let component: ConfirmTeTypeModalComponent;

    return new ModalComponentCommand(
      ConfirmTeTypeModalComponent,
      null,
      '',
      'nx3_label_check_te',
      [
        new ButtonModel('nx3_button_wrong_te_type', 'wrong_type', (event: any) => {
          modal.dismiss(null);
        })
        .withCssClass('btn btn-sm btn-secondary')
        .withOverflow(false),

        new ButtonModel('nx3_button_correct_te_type', 'correct_type', (event: any) => {
          modal.close(null);
        })
        .withCssClass('btn btn-sm btn-warning')
        .withOverflow(false),
      ],
      undefined,
      (m: ModalComponent) => {
        modal = m;
      },
      (c: ComponentRef<any>) => {
        component = c.instance;
        component.type = type;
      },
      (param: any) => {},
      confirmed,
      { size: 'md' }
    );
  }
}
