<div [formGroup]="formGroup">
  <lib-select-department
    *ngIf="options"
    [formControlName]="formControlName"
    [step]="field.step"
    [readonly]="field.readonly"
    [required]="field.required"
    [cssClass]="cssClass"
    [placeholder]="field.placeholder"
    [name]="name"
    [id]="id"
    [ngClass]="{ 'nx3-form-field-invalid': isInvalid() }"
    [options]="options"
    [autocomplete]="autocomplete"
  ></lib-select-department>
</div>
