<div [formGroup]="formGroup">
  <p [innerHtml]="'nx3_message_vehicle_type' | translate | safe: 'html'"></p>
  <div class="form-group">
  <label class="mb-1 nx3-form-field-label required" for="transportEquipmentType" translate="property_transportEquipmentType_label"></label>
  <ng-select
    class="form-control-sm p-0"
    [selectOnTab]="true"
    [clearable]="true"
    [searchable]="true"
    formControlName="transportEquipmentType"
    [(ngModel)]="transportEquipmentType"
  >
    <ng-option
      class="nav-item"
      *ngFor="let type of transportEquipmentTypes"
      [value]="type.name"
    >
      {{ type.label }}
    </ng-option>
  </ng-select>
</div>
