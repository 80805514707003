import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { Nx3CmsModule, Nx3ComponentsModule, Nx3PlatformagreementModule, Nx3QualificationModule } from '@nx3/nx3-core-ui';
import { SharedModule } from 'projects/shared/src/lib/shared.module';
import { DocShareCategoryFilterComponent } from './doc-share-category-filter/doc-share-category-filter.component';
import { PortalFooterComponent } from './portal-footer/portal-footer.component';
/**
 * Custom components specialized for this application
 */
@NgModule({
  declarations: [
    DocShareCategoryFilterComponent,
    PortalFooterComponent
  ],
  exports: [NgbNavModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    Nx3ComponentsModule,
    Nx3CmsModule,
    Nx3QualificationModule,
    SharedModule,
    Nx3PlatformagreementModule
  ]
})
export class ComponentsModule { }
