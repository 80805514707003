import { Component, ComponentRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResourceModel, Nx3Entity } from '@nx3/nx3-client';
import {
  ButtonModel,
  CommonNames,
  ModalComponent,
  ModalComponentCommand,
  Nx3Api
} from '@nx3/nx3-core-ui';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmTeTypeModalComponent } from '../confirm-te-type-modal/confirm-te-type-modal.component';


@Component({
  selector: 'app-vehicle-type-modal',
  templateUrl: './vehicle-type-modal.component.html',
  styleUrls: ['./vehicle-type-modal.component.scss'],
})
export class VehicleTypeModalComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({});
  transportEquipmentType: string;
  transportEquipmentTypes: any[] = [];

  constructor(public nx3: Nx3Api) {}

  static asModal(
    carrier: Nx3Entity
  ): ModalComponentCommand {
    let modal: ModalComponent;
    let component: VehicleTypeModalComponent;
    const buttonDisabledState = new BehaviorSubject(true);

    return new ModalComponentCommand(
      VehicleTypeModalComponent,
      null,
      'truck-arrow-right',
      'nx3_label_vehicle_type',
      [
        new ButtonModel('nx3_button_cancel', 'no', (event: any) => {
          modal.dismiss(null);
        })
        .withCssClass('btn btn-sm btn-secondary')
        .withOverflow(false),

        new ButtonModel('nx3_button_ok', 'yes', (event: any) => {
          function confirmType() {
            component.nx3.spinner.show();
            modal.close(component.transportEquipmentType)
          };

          if(component.nx3.client.auth.hasSecurityGroup('CARRIER_GROUP')) {
            component.nx3.modal.component(
              ConfirmTeTypeModalComponent.asModal(component.transportEquipmentType, confirmType)
            );
          } else {
            confirmType();
          }
        })
        .withCssClass('btn btn-sm btn-warning')
        .withOverflow(false)
        .withDisabled(buttonDisabledState),
      ],
      undefined,
      (m: ModalComponent) => {
        modal = m;
      },
      (c: ComponentRef<any>) => {
        component = c.instance;
        component.formGroup.statusChanges.subscribe(() => {
          buttonDisabledState.next(!component.formGroup.valid);
        });

        component.nx3.client.resource.getResourceModels(component.nx3.config.registrationServer).pipe(take(1)).subscribe((models: ResourceModel[]) => {
          for(const resource of models) {
            if (resource.refererClazz === 'de.seriea.nx3.nagel.carrier.transportequipment.list.TrEqListEntry') {
              component.transportEquipmentTypes.push({
                name: resource.name,
                label:component.nx3.i18n.translate.instant(resource.labelKey)
              });
            }
          }
          component.transportEquipmentTypes.sort((a, b) => {
            if(a.label < b.label) { return -1; }
            if(a.label > b.label) { return 1; }
            return 0;
          });
        });
      },
      (param: any) => {},
      (param: any) => {
        component.nx3.flash.set('vehicleCarrier', carrier);

        component.nx3.navigation.navigate([
          '',
          CommonNames.EDITOR,
          component.nx3.config.registrationServer,
          param,
          '-1',
          'treqlistentry'
        ])
      },
      { size: 'md' }
    );
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      transportEquipmentType: new FormControl(null, Validators.required),
    });
  }
}
