import { Nx3Api } from "@nx3/nx3-core-ui";
import { CustomMultiDocumentVerificationTaskExtension } from "projects/shared/src/lib/resource-extension/custom-multi-document-verification-extension";

export class PricingConditionsVerificationUserTaskExtension extends CustomMultiDocumentVerificationTaskExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3, "pricingconditionsverificationusertask", [
      "pricingConditions.firstDocument",
      "pricingConditions.secondDocument",
      "pricingConditions.thirdDocument",
    ]);
  }
}
