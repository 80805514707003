import { EntityPage, Nx3SearchFilter } from '@nx3/nx3-client';
import {
  DetailConfig,
  DetailEvent,
  EditorEvent,
  FormlayoutComponent, ListConfig,
  Nx3Api,
  Nx3DocumentExtension,
  nestedConfig
} from '@nx3/nx3-core-ui';

export class CustomDocumentExtension extends Nx3DocumentExtension {

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  configureDetail(config: DetailConfig): void {
    config.enableNotifications = false;
    config.enableDuplicate = false;

    config.layout.rootLayout()
    .row()
    .nestedColumn(12)
    .component(FormlayoutComponent,
      nestedConfig(false)
      .mapModel('entity', 'object')
    )
    .up()
  }

  onDetailInit(event: DetailEvent): void {
    super.onDetailInit(event);
    event.component.entity.download.contextString = '';
  }

  configureList(config: ListConfig) {
    super.configureList(config);
    config.setFixedView(false);
    config.enableCreate = false;
    config.enableCount = false;
    config.enableDuplicate = false;
    config.enableListSettings = false;
    config.enableGuidedTour = false;
    config.enableNotifications = false;
    config.pagination.pageSizes = [10, 25, 50, 75, 100];
    config.filter.hiddenFilterFields.push(
      'showTransportEquipmentDocuments',
      'ownDocuments',
      'blockedCarrier'
    );

  }

  onAfterSearch(data: { filter: Nx3SearchFilter; page: EntityPage; }): void {
    for(const entry of data.page.content) {
      if(entry.modelExtension.hideValidity) {
        entry.validFrom = null;
        entry.validTo = null;
      }
      entry.download.contextString = '';
    }
  }

  onEditorInit(event: EditorEvent): void {
    if(event.component.entity.modelExtension.documentType === 'vehicleLicence') {
      event.component.metadata.getFieldByName('modelExtension.countryCode').hidden = false;
      event.component.metadata.getFieldByName('modelExtension.countryCode').required = true;
    } else {
      event.component.metadata.getFieldByName('modelExtension.countryCode').hidden = true;
    }
  }

}
