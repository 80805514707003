import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Component, OnInit } from '@angular/core';
import { ActivationEnd } from '@angular/router';
// tslint:disable-next-line: max-line-length
import { DashboardLayoutService, ExtensionService, FieldWidgetFactory, Nx3Api, Nx3CommandType, PlatformAgreementService, ResourceExtension } from '@nx3/nx3-core-ui';
import { GuidedTour } from 'ngx-guided-tour';
import { CustomDocumentUploadUserTaskExtension } from 'projects/shared/src/lib/resource-extension/custom-document-upload-extension';
import { CustomUserTaskExtension } from 'projects/shared/src/lib/resource-extension/custom-usertask-extension';
import { DocumentUploadInformationUserTaskExtension } from 'projects/portal/src/app/resource-extensions/document-upload-information-usertask-extension';
import { InsSubContrTranspUploadUserTaskExtension } from 'projects/shared/src/lib/resource-extension/ins-sub-contr-transp-upload-usertask-extension';
import { MinwageUploadUserTaskExtension } from 'projects/shared/src/lib/resource-extension/minwage-upload-usertask-extension';
import { PubLiabilityInsuranceUploadUserTaskExtension } from 'projects/shared/src/lib/resource-extension/pub-liability-insurance-upload-usertask-extension';
import { AppExtension } from './app-extension';
import { DefaultExtension } from './resource-extensions/default-extension';
import { SemitrailerExtension } from './resource-extensions/semitrailer-extension';
import { TractorunitExtension } from './resource-extensions/tractorunit-extension';
import { TrailerExtension } from './resource-extensions/trailer-extension';
import { TransportEquipmentExtension } from './resource-extensions/transport-equipment-extension';
import { TransportEquipmentListEntryExtension } from './resource-extensions/transport-equipment-listentry-extension';
import { TransporterOver3k5Extension } from './resource-extensions/transporter-over-3k5-extension';
import { TransporterUpTo3k5Extension } from './resource-extensions/transporter-up-to-3k5-extension';
import { TruckOver7k5Extension } from './resource-extensions/truck-over-7k5-extension';
import { SmallfleetExtension } from './resource-extensions/smallfleet-extension';
import { LargefleetExtension } from './resource-extensions/largefleet-extension';
import { CustomEmployeeExtension } from './resource-extensions/custom-employee-extension';
import { CustomDocumentExtension } from './resource-extensions/custom-document-extension';
import { CarrierContractExtension } from './resource-extensions/carrier-contract-extension';
import { CustomRegionExtension } from './resource-extensions/custom-region-extension';
import { CarrierRelationExtension } from './resource-extensions/carrier-relation-extension';
import { CustomDocumentsShareExtension } from './resource-extensions/custom-documents-share-extension';
import { DepartmentExtension } from './resource-extensions/department-extension';
import { TargetExtension } from './resource-extensions/target-extension';
import { CarrierExtension } from './resource-extensions/carrier-extension';
import { ProvideMasterdataUserTaskExtension } from './resource-extensions/provide-masterdata-usertask-extension';
import { PricingConditionsVerificationUserTaskExtension } from './resource-extensions/pricing-conditions-verification-usertask-extension';
import { CustomVerificationDecisionUserTaskExtension } from 'projects/shared/src/lib/resource-extension/custom-verification-decision-usertask-extension';
import { CustomQualificationExtension } from 'projects/shared/src/lib/resource-extension/custom-qualification-extension';
import { OperativeUserTaskExtension } from 'projects/shared/src/lib/resource-extension/operative-usertask-extension';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public dashboardTour: GuidedTour = {
    tourId: 'registration',
    useOrb: false,
    steps: []
  };

  constructor(
    private nx3: Nx3Api,
    private platformAgreements: PlatformAgreementService) {
  }

  /**
   * The applications first initialization callback
   */
  ngOnInit(): void {
    registerLocaleData(localeDe, 'de');

    const extensionService = this.nx3.injector.get(ExtensionService);

    // register an application extension that extends behaviour of the global application layout and global components
    extensionService.registerApplicationExtension(new AppExtension(this.nx3, this.nx3.injector.get(FieldWidgetFactory), this.nx3.injector.get(DashboardLayoutService)));

     // launch the resource extension system
    extensionService.configureResourceExtensions(
      // register new extensions or override the default extensions with custom ones
      [
        CarrierContractExtension,
        CarrierExtension,
        CarrierRelationExtension,
        CustomDocumentExtension,
        CustomDocumentsShareExtension,
        CustomDocumentUploadUserTaskExtension,
        CustomEmployeeExtension,
        CustomQualificationExtension,
        CustomRegionExtension,
        CustomUserTaskExtension,
        CustomVerificationDecisionUserTaskExtension,
        DepartmentExtension,
        DocumentUploadInformationUserTaskExtension,
        InsSubContrTranspUploadUserTaskExtension,
        LargefleetExtension,
        MinwageUploadUserTaskExtension,
        OperativeUserTaskExtension,
        PricingConditionsVerificationUserTaskExtension,
        ProvideMasterdataUserTaskExtension,
        PubLiabilityInsuranceUploadUserTaskExtension,
        SemitrailerExtension,
        SmallfleetExtension,
        TargetExtension,
        TractorunitExtension,
        TrailerExtension,
        TransportEquipmentExtension,
        TransportEquipmentListEntryExtension,
        TransporterOver3k5Extension,
        TransporterUpTo3k5Extension,
        TruckOver7k5Extension
      ],
      // default extension will be used for all resources that are not extended
      DefaultExtension,
      // post process all created extensions to apply global defaults
      (extension: ResourceExtension) => {
        extension.editor.form.enableValidationErrorPanel = false;
      }
    );

    // instantly scroll top at every page navigation
    this.nx3.router.events.subscribe((event: any) => {
      if (event instanceof ActivationEnd) {
        this.nx3.client.events.fireEvent(Nx3CommandType.SCROLL_TOP, {speed: 0});
      }
    });

    // create modal which forces the user to accept platform agreements
    this.platformAgreements.registerAcceptModal(['dashboard', 'list']);

    // install navigation endpoints handling hooks for open-id server integration
    this.nx3.navigation.authEndpoints();

    // configure content security policy
    this.nx3.win.configureContentSecurityPolicy();
  }
}
