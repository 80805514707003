import { Nx3BeanModel, Nx3DataType, Nx3FieldModel, Nx3FormRow, Nx3FormStep } from '@nx3/nx3-client';
import { EditorConfig, EditorEvent, FormBehavior, Nx3Api } from '@nx3/nx3-core-ui';
import { SelectDepartmentFieldComponent } from 'projects/shared/src/lib/select-department-field/select-department-field.component';
import { FleetService } from 'projects/shared/src/lib/service/fleet.service';
import { AbstractPortalExtension } from './abstract-portal-extension';

export class CarrierExtension extends AbstractPortalExtension {
  fleetService: FleetService;

  constructor(public nx3: Nx3Api) {
    super('carrier', nx3);
    this.fleetService = this.nx3.injector.get(FleetService);
  }

  configureEditor(config: EditorConfig): void {
    config.form.enableSectionHeaders = false;
  }

  onEditorInit(event: EditorEvent): void {
    if(this.nx3.client.auth.hasSecurityGroup('REGISTERED_GROUP')) {
      const user = this.nx3.client.businesspartner.getPersonInstant(this.nx3.config.registrationServer);
      event.component.entity.actor.communication.preferedLanguage = user.actor.communication.preferedLanguage;

      this.createUserDefinedDepartmentField(this.editorInstance.metadata);

      const org = this.nx3.client.businesspartner.getOrganizationInstant(this.nx3.config.registrationServer);
      if(org.modelExtension.registrationSource === 'LEGACY_APPLICATION_PROCESS') {
        const categorizationStep = new Nx3FormStep();
        categorizationStep.icon = "file-invoice";
        categorizationStep.label ="section_categorization";
        categorizationStep.sections = ["s5"];

        const largeFleetStep = new Nx3FormStep();
        largeFleetStep.icon = "truck-container";
        largeFleetStep.label ="section_largeFleet";
        largeFleetStep.sections = ["s6"];

        const smallFleetStep = new Nx3FormStep();
        smallFleetStep.icon = "van-shuttle";
        smallFleetStep.label ="section_smallFleet";
        smallFleetStep.sections = ["s7"];

        event.component.metadata.form.steps.push(categorizationStep);
        event.component.metadata.form.steps.push(largeFleetStep);
        event.component.metadata.form.steps.push(smallFleetStep);

        event.component.config.form.formBehavior = (behavior: FormBehavior) => {
          this.fleetService.largeFleetFormBehavior(behavior, 'largeFleetTemp');
          this.fleetService.smallFleetFormBehavior(event.component, behavior, 'smallFleetTemp');

          behavior
            .global(() => true)
            .setRequired('categorization.localTransport', true)
            .setRequired('categorization.oneShiftModel', true)
            .setRequired('categorization.doubleShiftModel', true)
            .setRequired('categorization.trampingTradeNational', true)
            .setRequired('categorization.trampingTradeInternational', true)
            .setRequired('categorization.oneWay', true)
            .setRequired('categorization.seasonal', true)
            .setRequired('categorization.permanent', true)
            .setRequired('categorization.spot', true)
          ;

          behavior
            .when('actor.address.country', (object: any) => {
            return object.actor.address.country;
            })
            .run((object: any) => {
              this.nx3.flash.set('actor.communication.phoneNumber.country', object.actor.address.country);
              this.nx3.flash.set('actor.communication.mobileNumber.country', object.actor.address.country);
              this.nx3.flash.set('actor.communication.faxNumber.country', object.actor.address.country);
              this.nx3.flash.set('actor.vatId.country', object.actor.address.country);
            })
          ;
        };
      } else {
        event.component.config.form.formBehavior = (behavior: FormBehavior) => {
          behavior
            .when('actor.address.country', (object: any) => {
              return object.actor.address.country;
            })
            .run((object: any) => {
              this.nx3.flash.set('actor.communication.phoneNumber.country', object.actor.address.country);
              this.nx3.flash.set('actor.communication.mobileNumber.country', object.actor.address.country);
              this.nx3.flash.set('actor.communication.faxNumber.country', object.actor.address.country);
              this.nx3.flash.set('actor.vatId.country', object.actor.address.country);
            })
          ;
        };
      }
    }
  }

  createUserDefinedDepartmentField(metadata: Nx3BeanModel): Nx3FieldModel {
    let userDefinedDepartmentField = metadata.getFieldByName('userDefinedDepartment');
    if(!metadata.getFieldByName('userDefinedDepartment')) {
      userDefinedDepartmentField = new Nx3FieldModel();
      userDefinedDepartmentField.name = 'userDefinedDepartment';
      userDefinedDepartmentField.type = Nx3DataType.TEXT;
      userDefinedDepartmentField.required = true;
      userDefinedDepartmentField.labelKey = 'property_department_label';
      userDefinedDepartmentField.componentType = SelectDepartmentFieldComponent;
      userDefinedDepartmentField.transientProperty = true;
      const row = new Nx3FormRow();
      row.fields = ['userDefinedDepartment'];
      metadata.addField(userDefinedDepartmentField);
      metadata.form.getSectionsByKey('s1').rows.push(row);
    }

    return userDefinedDepartmentField;
  }

}
