import { Component, ComponentRef } from '@angular/core';
import {
  ButtonModel, ModalComponent,
  ModalComponentCommand
} from '@nx3/nx3-core-ui';


@Component({
  selector: 'app-missing-properties-modal',
  templateUrl: './missing-properties-modal.component.html',
  styleUrls: ['./missing-properties-modal.component.scss'],
})
export class MissingPropertiesModalComponent {
  missingProperties: any[];

  constructor() {}

  static asModal(
    missingProperties: any[]
  ): ModalComponentCommand {
    let modal: ModalComponent;
    let component: MissingPropertiesModalComponent;

    return new ModalComponentCommand(
      MissingPropertiesModalComponent,
      null,
      'check',
      'nx3_button_release_vehicle',
      [
        new ButtonModel('nx3_button_ok', 'yes', (event: any) => {
          modal.close(null);
        })
        .withCssClass('btn btn-sm btn-warning')
        .withOverflow(false),
      ],
      undefined,
      (m: ModalComponent) => {
        modal = m;
      },
      (c: ComponentRef<any>) => {
        component = c.instance;
        component.missingProperties = missingProperties;
      },
      (param: any) => {},
      (param: any) => {},
      { size: 'md' }
    );
  }
}
