<div class="pb-3">
  <ul
    ngbNav
    #nav="ngbNav"
    [(activeId)]="active"
    keyboard="true"
    class="nav nav-tabs nav-justified"
    (activeIdChange)="navChange()"
  >
    <li ngbNavItem [ngbNavItem]="'ALL'">
      <a ngbNavLink class="nx3-print">
        <span translate="nx3_button_all"></span>
      </a>
    </li>
    <li ngbNavItem [ngbNavItem]="cat.id" *ngFor="let cat of categories">
      <a ngbNavLink class="nx3-print">
        <span [translate]="cat.key"></span>
      </a>
    </li>
  </ul>
</div>
