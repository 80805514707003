import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { HotkeyModule } from 'angular2-hotkeys';
// tslint:disable-next-line: max-line-length
import { GeoService, GoogleGeoService, I18nService, Nx3CmsModule, Nx3CommentsModule, Nx3ComponentsModule, Nx3LifecycleModule, Nx3PlatformagreementModule, Nx3PortalModule, Nx3QualificationModule, Nx3ResourceExtensionsModule, Nx3ServiceModule, Nx3StatemachineModule, Nx3TasksModule, Nx3TransportModule, Nx3ValuationModule, Nx3VerificationModule } from '@nx3/nx3-core-ui';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { SharedModule } from 'projects/shared/src/lib/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { applicationConfig } from './app.config';
import { ComponentsModule } from './components/components.module';
import { PagesModule } from './pages/pages.module';
import { RedirectComponent } from './redirect/redirect.component';

@NgModule({
  providers: [
    { provide: GeoService, useClass: GoogleGeoService },
    GuidedTourService
  ],
  declarations: [
    AppComponent,
    RedirectComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HotkeyModule.forRoot({
      cheatSheetCloseEsc: true,
      cheatSheetDescription: applicationConfig.applicationName
    }),
    TranslateModule.forRoot(I18nService.createTranslateConfig()),
    GuidedTourModule.forRoot(),
    Nx3ServiceModule.forRoot(applicationConfig),
    Nx3ComponentsModule,
    Nx3PortalModule,
    Nx3TasksModule,
    Nx3VerificationModule,
    Nx3QualificationModule,
    Nx3CmsModule,
    Nx3PlatformagreementModule,
    Nx3TransportModule,
    Nx3CommentsModule,
    Nx3StatemachineModule,
    Nx3LifecycleModule,
    Nx3ValuationModule,
    Nx3ResourceExtensionsModule,
    SharedModule,
    AppRoutingModule,
    ComponentsModule,
    PagesModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
