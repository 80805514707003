import { ListConfig, Nx3Api, ReferenceListConfig, ReferenceListEvent } from '@nx3/nx3-core-ui';
import { AbstractPortalExtension } from './abstract-portal-extension';

export class CarrierContractExtension extends AbstractPortalExtension {

  constructor(public nx3: Nx3Api) {
      super('carriercontract', nx3);
  }

  configureList(config: ListConfig) {
    super.configureList(config);
    config.filter.hiddenFilterFields.push('ownContracts');
  }

  configureReferenceLists(referenceListConfig: Map<string, ReferenceListConfig>): void {
    const config = new ReferenceListConfig();
    config.enableCreate = false;
    config.enableSelection = false;
    config.table.toolEdit = false;
    config.table.toolDelete = false;
    config.filter.hiddenFilterFields.push('showTransportEquipmentDocuments', 'ownDocuments');
    referenceListConfig.set('nx3document', config);
  }

  onReferenceListAfterView(event: ReferenceListEvent): void {
    if(event.component.metadata.name === 'Nx3Document') {
      const customerDocTypes = [
        'CONTRACT','CONTRACT_SERVICE_DESCRIPTION','CONTRACT_SALES_TAX_OBLIGATION','CONTRACT_MOBILE_DATA_ACQUISITION',
        'CONTRACT_LOADING_EQUIPMENT','CONTRACT_CONTAINER_TAKEOVERS','CONTRACT_FUEL_SUPPLY','CONTRACT_HYGIENE_SAFETY',
        'CONTRACT_PRICING_CONDITIONS','CONTRACT_LEASING_ADVERTISING_SPACE','CONTRACT_MINIMUM_WAGE','CONTRACT_TEMPERATURE_SENSORS',
        'CONTRACT_COMMITMENT_NAGEL_GROUP_TRAILERS','CONTRACT_CARRIER_SPECIAL_AGREEMENT','CONTRACT_SPECIAL_VEHICLE_AGREEMENT',
        'CONTRACT_INSURANCE_NAGEL_GROUP_EQUIPMENT','CONTRACT_ADMINISTRATION_NAGEL_GROUP','CONTRACT_EU_LICENSE_SUBCONTRACTOR'
      ];
      const docTypes = event.component.metadata.getFieldByName('modelExtension.customerDocumentType');
      docTypes.options = docTypes.options.filter((type) => customerDocTypes.includes(type.key));
    }
  }
}
