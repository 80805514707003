import { DetailComponent, DocumentUploadUserTaskExtension, EditorConfig, EditorEvent, FormBehavior, ListComponent, ModalComponent, Nx3Api } from '@nx3/nx3-core-ui';
import { DocumentUploadService } from '../service/document-upload.service';


export class CustomDocumentUploadUserTaskExtension extends DocumentUploadUserTaskExtension {
  constructor(public nx3: Nx3Api, public documentUploadService: DocumentUploadService) {
    super(nx3);
    this.documentUploadService = this.nx3.injector.get(DocumentUploadService);
  }

  onEditorInit(event: EditorEvent): void {
    if(event.component.entity.modelExtension.hideValidity) {
      event.component.metadata.getFieldByName('modelExtension.validFrom').hidden = true;
      event.component.metadata.getFieldByName('modelExtension.validTo').hidden = true;
    } else {
      event.component.metadata.getFieldByName('modelExtension.validFrom').hidden = false;
      event.component.metadata.getFieldByName('modelExtension.validTo').hidden = false;
    }

    if(event.component.entity.modelExtension.documentType === 'vehicleLicence') {
      event.component.metadata.getFieldByName('modelExtension.countryCode').hidden = false;
      event.component.metadata.getFieldByName('modelExtension.countryCode').required = true;
    } else {
      event.component.metadata.getFieldByName('modelExtension.countryCode').hidden = true;
    }

    event.component.config.form.formBehavior = (behavior: FormBehavior) => {
      this.documentUploadService.documentUploadFormBehavior(event.component, behavior);
    };
  }

  configureEditor(config: EditorConfig): void {
    super.configureEditor(config);
    config.form.enableSectionHeaders = true;
  }

  onEditorDestroy(event: EditorEvent): void {
    this.documentUploadService.unregisterDropzoneEvents(event.component);
  }

  configureModal(list: ListComponent, detail: DetailComponent, modalInstance: ModalComponent): void {
    super.configureModal(list, detail, modalInstance);
    this.documentUploadService.disableCompleteOnInvalidForm(detail, modalInstance);
  }
}
