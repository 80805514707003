import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Nx3Entity } from '@nx3/nx3-client';
import { DetailComponent, DropzoneEventType, EditorComponent, FormBehavior, ModalComponent, Nx3Api, fromToDate } from '@nx3/nx3-core-ui';
import { DropzoneEvent } from '@nx3/nx3-core-ui/lib/nx3-components/dropzone/dropzone.component';
import { take } from 'rxjs';

/**
 * Date and datetime utils used by components
 *
 * @author martin.bueltel(at)serie-a.de
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentUploadService {

  constructor(public nx3: Nx3Api) { }

  createFromToDateValidator(fromField: any, toField: any) {
    if (fromField?.field?.hidden === false && toField?.field?.hidden === false) {
      fromField.setValidators([
        fromField.validator,
        fromToDate(fromField, toField),
      ]);

      toField.setValidators([
        toField.validator,
        fromToDate(fromField, toField),
      ]);
    }
  };

  registerDropzoneEvents(editorInstance: EditorComponent) {
    this.nx3.client.events.on(
      DropzoneEventType.FILE_SELECTED,
      (dropzoneEvent: DropzoneEvent) => {
        // 0 = DropzoneType.FILE
        if(dropzoneEvent.data.component.type == 0) {
          editorInstance.form.get(dropzoneEvent.name + '.name').setValue(dropzoneEvent.data.fileName);
        }
      },
      editorInstance
    );

    this.nx3.client.events.on(
      DropzoneEventType.FILE_REMOVED,
      (dropzoneEvent: DropzoneEvent) => {
        // 0 = DropzoneType.FILE
        if(dropzoneEvent.data.component.type == 0) {
          editorInstance.form.get(dropzoneEvent.name + '.name').setValue(null);
        }
      },
      editorInstance
    );
  };

  unregisterDropzoneEvents(editorInstance: EditorComponent) {
    this.nx3.client.events.unregister(DropzoneEventType.FILE_SELECTED, editorInstance);
    this.nx3.client.events.unregister(DropzoneEventType.FILE_REMOVED, editorInstance);
  };

  multiDocumentUploadFormBehavior(editorComponent: EditorComponent, behavior: FormBehavior, mainDocument: string, optionalDocuments: string[] = []) {
    behavior.global(() => true)
      .setRequired(mainDocument + '.name', true)
      .run((object: any) => {
        this.registerDropzoneEvents(editorComponent);

        this.nx3.client.rest.post(this.nx3.config.registrationServer, '/application/document/create', {}).pipe(take(1)).subscribe((result) => {
          if(!object[mainDocument].content) {
            object[mainDocument] = { ...result };
          }
          for(const optional of optionalDocuments) {
            if(!object[optional].content) {
              object[optional] = { ...result };
            }
          }

          const fromField = editorComponent.form.get(mainDocument + '.validFrom');
          const toField = editorComponent.form.get(mainDocument + '.validTo');
          this.createFromToDateValidator(fromField, toField);
        });
      });

    behavior.global((object: any) => editorComponent.metadata.getFormSectionByLabel('section_rejection') && !object.notAdducedReason)
      .setSectionVisible('section_rejection', false);

    behavior.global((object: any) => !object[mainDocument].language)
      .setStaticValue(mainDocument + '.language', this.nx3.client.i18n.getLanguage());

    for(const optional of optionalDocuments) {
      behavior
        .global((object: any) => object.hideValidity)
        .setHidden(optional + '.validFrom', true)
        .setHidden(optional + '.validTo', true);

      behavior
        .when(optional + '.name', (object: any) => {
          return !object.hideValidity && object[optional]?.name;
        })
        .setRequired(optional + '.validFrom', true)
        .setRequired(optional + '.validTo', true)
        .run(() => {
          const fromField = editorComponent.form.get(optional + '.validFrom');
          const toField = editorComponent.form.get(optional + '.validTo');
          this.createFromToDateValidator(fromField, toField);
        });

      behavior
        .when(optional + '.name', (object: any) => {
          return !object.hideValidity && object[optional]?.name === null;
        })
        .clearValidators(optional + '.validFrom')
        .clearValidators(optional + '.validTo');
    }
  };

  documentUploadFormBehavior(editorComponent: EditorComponent, behavior: FormBehavior) {
    behavior.global(() => true)
      .setRequired('document.name', true)
      .run((object: any) => {
        this.registerDropzoneEvents(editorComponent);

        this.nx3.client.rest.post(this.nx3.config.registrationServer, '/application/document/create', {}).pipe(take(1)).subscribe((result) => {
          if(!object.document.content) {
            object.document = result;
          }

          const fromField = editorComponent.form.get('modelExtension.validFrom');
          const toField = editorComponent.form.get('modelExtension.validTo');
          this.createFromToDateValidator(fromField, toField);
        });
      });

    behavior.global((object: any) => !object.modelExtension.notAdducedReason)
      .setSectionVisible('section_rejection', false);

    behavior.global((object: any) => !object.modelExtension.documentTemplateDownload)
      .setSectionVisible('section_documenttemplate', false);

    behavior.global((object: any) => !object.document.language)
      .setStaticValue('document.language', this.nx3.client.i18n.getLanguage());
  };

  multiDocumentUploadOnBeforeSave(entity: Nx3Entity, mainDocument: string, optionalDocuments?: string[]) {
    if (entity[mainDocument].validFrom) {
      entity[mainDocument].validFrom = this.nx3.dates.toDateString(entity[mainDocument].validFrom) + 'T00:00:00';
    }
    if (entity[mainDocument].validTo) {
      entity[mainDocument].validTo = this.nx3.dates.toDateString(entity[mainDocument].validTo) + 'T23:59:59';
    }

    for(const optional of optionalDocuments) {
      if(entity[optional]?.content) {
        if (entity[optional].validFrom) {
          entity[optional].validFrom = this.nx3.dates.toDateString(entity[optional].validFrom) + 'T00:00:00';
        }
        if (entity[optional].validTo) {
          entity[optional].validTo = this.nx3.dates.toDateString(entity[optional].validTo) + 'T23:59:59';
        }
      } else {
        entity[optional] = null;
      }
    }
  };

  disableCompleteOnInvalidForm(detail: DetailComponent, modalInstance: ModalComponent) {
    const editor: EditorComponent = detail.componentGrids.getDynamics(EditorComponent)[0].getInstance();
    editor.formComponent.formChanged.subscribe((form: FormGroup) => {
      let disableButton = true;
      if (form.valid) {
        disableButton = false;
      } else {
        disableButton = true;
      }

      for (const btn of modalInstance.buttons) {
        if (btn.id === 'complete_task') {
          btn.disabled = disableButton;
        }
      }
    });
  };

}
